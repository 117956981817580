import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CustomFilters from '../../../Components/CustomFilters';
import { disabledButtonByPermission } from '../../../Components/Permissions';
import { PermissionsContext } from '../../../Layout/PermissionContext';
import CustomTable from '../../../Components/CustomTable';
import { Dropdown} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CustomPagination from '../../../Components/CustomPagination';
import axios from 'axios';
import BASEURL from '../../../Config/global';
import { questionModal, successModal } from '../../../Components/CustomModal';
import { deleteRequest } from '../../../Util/requests';

const CategoryTable = ({ name, categoryUrl, addCategoryPath, editCategoryPath }) => {

  const { permissions } = useContext(PermissionsContext);
  const [entries, setEntries] = useState(10);
  const [categoryCount, setCategoryCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    getDataFromApi();
  }, [entries,offset, searchValue]);

  // Fetch Apis
  const getDataFromApi = async () => {
    try {
      const response = await axios.get(
        // https://backend.dev.relax.scofa.com/api/sounds/mixFeatureCategories
        `${BASEURL}${categoryUrl}?limit=${entries}&offset=${offset}&search=${searchValue}&start_date=&end_date=`
      );
      const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
      setCategoryData(sortedData)
      setCategoryCount(response.data.count)
    } catch (error) {
      console.error(error);
    }
  }

  const featuredMixCategoryDelete = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to delete category ${name}?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          featuredMixCategoryDeleted(id);
          successModal.fire({
            text: `Category ${name} has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const featuredMixCategoryDeleted = async (id) => {
    try {
      const response = await deleteRequest(`${categoryUrl}/${id}`);
      if (!response?.data.error) {
        getDataFromApi();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeArticleEntries = (no) => {
    setOffset(0)
    setEntries(no)
  }

  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }

  const categoryHeader = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "categories",
      title: "Categories",
    },
    {
      key: "subcategory",
      title: "No of Sub categories",
    },
    {
      key: "noOfTracks",
      title: "No of tracks",
    },
    {
      key: "underagerestriction",
      title: "under age restriction",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 text-end">
          <Link
            to={addCategoryPath}
            className={`customButton primaryButton`}
          >
            Add {name} Category
          </Link>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <CustomFilters
            enteries={entries}
            totalCount={categoryCount}
            entriesFilter={true}
            setEnteries={handleChangeArticleEntries}
            filterSearch={true}
            filterSearchValue={searchValue}
            setFilterSearchValue={setSearchValue}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <CustomTable headers={categoryHeader}>
            <tbody>
              {categoryData.length > 0 && categoryData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <button type="button" className="btn lightColor outline-0 border-0" onClick={() => { }}>{index + 1}</button>
                  </td>
                  <td className="text-capitalize">{item.name}</td>
                  <td className="text-capitalize">{item.sub_category_count}</td>
                  <td className="text-capitalize">{item.sound_count}</td>
                  <td className="text-capitalize">{item.child_content?"YES":"NO"}</td>

                  <td>
                    <Dropdown className="tableDropdown">
                      <Dropdown.Toggle
                        variant="transparent"
                        className="notButton classicToggle"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        align="end"
                        className="tableDropdownMenu"
                      >
                        <Link
                          className={`tableAction ${modulesWisePermission('can_edit')}`}
                          to={`/category-management/${editCategoryPath}/${item.id}`}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="tableActionIcon"
                          />
                          Edit
                        </Link>
                        <button
                          type="button"
                          className={`tableAction ${modulesWisePermission('can_delete')}`}
                          onClick={() => {
                            featuredMixCategoryDelete(
                              item.id,
                              item.name
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="tableActionIcon"
                          />
                          Delete
                        </button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </CustomTable>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          {" "}
          <CustomPagination
            enteries={entries}
            totalCount={categoryCount}
            setCurrentPage={setOffset}
            currentPage={offset}
          />
        </div>
      </div>
      
    </>
  )
}

export default CategoryTable