import React, { useEffect, useState } from 'react'
import { AddImageComponent, EditImageComponent } from './ImageComponent';
import BackButton from '../../Components/BackButton';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CustomButton from '../../Components/CustomButton';
import { patchRequest, postRequest } from '../../Util/requests';
import { useNavigate, useParams } from 'react-router';
import { questionModal, successModal } from '../../Components/CustomModal';
import BASEURL from '../../Config/global';
import axios from 'axios';


const CategoryComponent = ({ name, baseAPI }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [inputValue, setinputValue] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [soundImage, setSoundImage] = useState(null);
    const [childContent, setChildContent] = useState(false);
    const [thumbnail, setSoundThumbnail] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(
                    `${BASEURL}${baseAPI}`
                );

                setinputValue(response.data.data[0].name);
                setSoundThumbnail(response.data.data[0].banner)
                setSelectedImage(response.data.data[0].banner)
                setChildContent(response.data.data[0].child_content)
            } catch (error) {
                console.error(error);
            }
        }
        if (id) {
            fetchData();
        }
        console.log(thumbnail)
    }, []);
    const handleChange = (event) => {
        setinputValue(event.target.value);
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);

        if (file === null || file === "undefined") {
            setSoundImage(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setSoundImage(reader.result);
                setSelectedImage(reader.result);

                console.log(reader)
            };
        }
    };


    const postData = async () => {

        const raw = JSON.stringify({
            "name": inputValue,
            "banner": selectedImage,
            "child_content": childContent
        });
        try {
            if (id) {
                const response = await patchRequest(baseAPI, raw)
            } else {
                const response = await postRequest(baseAPI, raw)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputValue) {
            questionModal
                .fire({
                    title: `Do you want to ${id ? "Update" : "Add"} this category?`,
                    confirmButtonText: id ? "Update" : "Add",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        postData();
                        successModal.fire({
                            text: `Categories ${id ? "Updated" : "Added"} Successfully`,
                            confirmButtonText: "Continue",
                        });
                        navigate("/category-management");
                    }
                });
        }
    };
    const handleRemoveImage = (event) => {
        setSoundImage(null)
        setSelectedImage(null)
        setSoundThumbnail(null)
    }

    return (
        <>
            <DashboardLayout>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                            <h2 className="mainTitle">
                                <BackButton />
                                {id ? `Edit ${name} Category` : `Add ${name} Category`}
                            </h2>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="row mb-5">
                                <div className="col-lg-6 mb-2">
                                    <div className="inputWrapper">
                                        <label className="mainLabel">Category*</label>
                                        <div className="d-flex align-items-center gap-2">
                                            <input
                                                type="text"
                                                placeholder="Enter Category"
                                                required=""
                                                name="category"
                                                className="mainInput"
                                                value={inputValue}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <input
                                            type="checkbox"
                                            name="featured"
                                            id="featured"
                                            onChange={() => {
                                                setChildContent(!childContent);
                                            }}
                                            value={childContent}
                                            checked={childContent ? true : false}
                                        />
                                        <label htmlFor="featured" className="mainLabel ms-1">
                                            Underage Restriction
                                        </label>
                                    </div>

                                </div>
                                {
                                    id ? <EditImageComponent
                                        thumbnail={thumbnail}
                                        handleImageChange={handleImageChange}
                                        soundImage={soundImage}
                                        handleRemoveImage={handleRemoveImage}
                                    /> : <AddImageComponent
                                        soundImage={soundImage}
                                        handleImageChange={handleImageChange}
                                        handleRemoveImage={handleRemoveImage}
                                    />
                                }
                               

                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <CustomButton
                                        type="button"
                                        variant="primaryButton"
                                        text={id ? "Update" : "Add"}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </>
    );
};
export default CategoryComponent