import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import PermissionDetails from '../../Components/PermissionDetail'
import BackButton from '../../Components/BackButton'
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomInput from '../../Components/CustomInput';
import axios from 'axios';
import BASEURL from '../../Config/global';
import CustomLoader from '../../Components/CustomLoader';
import { errorModal, questionModal } from '../../Components/CustomModal';
import { patchRequest } from '../../Util/requests';

const ViewRoleDetails = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const roleId = searchParams.get('role_id')

    const [backButtonHeading, setBackButtonHeading] = useState('')
    const [selectedPermissions, setSelectedPermissions] = useState();
    const [roleName, setRoleName] = useState(searchParams.get('role_name'));

    useEffect(() => {
        if (roleId) {
            getUserRoleDetails()
            setBackButtonHeading("Manage Roles")
        }
    }, [roleId])
    useEffect(() => {

    }, [])
  
    const getUserRoleDetails = async () => {
        let data = '';
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${BASEURL}/api/permissions/userroledetails/${roleId}`,
            headers: {},
            data: data
        };
        await axios.request(config)
            .then((response) => {
                const { data } = response.data;
                setSelectedPermissions(data)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const areAllPermissionsFalse = (permission) => {
        if (!permission || permission?.length === 0) {
            return false
        }
        let flag = []
        for (let index = 0; index < permission.length; index++) {
            const element = permission[index];
            let actualPermissions = Object.keys(element).filter((key) => key.startsWith('can_')).some(key => element[key] === true);
            if (actualPermissions) {
                flag.push(true)
            }
        }
        return flag.length > 0;
    };

    const handeAddPermissions = async () => {
        let data = JSON.stringify({
            "name": roleName,
            "permissions": selectedPermissions
        });
        await patchRequest(`/api/permissions/userrolelist/${roleId}`, data)
        .then((response) => {
            const { error } = response.data
            if (!error) {
                navigate(-1)
            } else {
                questionModal
                    .fire({
                        title: response?.data?.message,
                        confirmButtonText: "continue",
                    })
            }
        })
        .catch((error) => {
            console.log(error);
        });
        
            

    }
    const handleSubmit = (id) => {
        if (roleName === "") {
            questionModal
                .fire({
                    title: "Please Add Role name",
                    confirmButtonText: "Continue",
                })
            return
        }
        const check = areAllPermissionsFalse(selectedPermissions)
        if (!check) {
            errorModal
                .fire({
                    title: "Please select some permissions for this role.",
                    confirmButtonText: "Continue",
                })
            return
        }
        questionModal
            .fire({
                title: "Do you want to update this role?",
                confirmButtonText: "Update",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await handeAddPermissions();

                }
            });
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setRoleName(value)
    }
    if (!selectedPermissions) {
        return <CustomLoader />
    }
    return (
        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">
                        <BackButton />
                        {backButtonHeading}
                    </h2>
                </div>
                <div className="col-lg-4 text-end mb-2">
                    <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill' onClick={handleSubmit}>
                        save changes
                    </button>
                </div>
            </div>
            <div className="col-lg-6 m-2">
                <CustomInput
                    label="Role Name"
                    required
                    id="fname"
                    type="text"
                    labelClass="mainLabel"
                    inputClass="mainInput"
                    name="first_name"
                    value={roleName}
                    onChange={handleChange}
                />
            </div>
            <div className='col-lg-6 m-2'>
            <PermissionDetails selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />
            </div>
        </DashboardLayout>
    )
}

export default ViewRoleDetails