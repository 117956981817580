import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthLayout } from './../../Layout/AuthLayout'
import CustomInput from "../../Components/CustomInput"
import CustomButton from '../../Components/CustomButton';

import "./style.css";
import BASEURL from '../../Config/global';
import axios from 'axios';
import { postRequest } from '../../Util/requests';



const ForgetPassword = () => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: false, text: "" });

  useEffect(() => {
    document.title = 'Relax Scofa | Password Recovery';
  }, [])

  const handleClick = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email?.trim());
    if (!formData.email || formData.email === "") {
      setErrorMsg({ error: true, text: "Please enter email" })
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(`${BASEURL}/api/user/forget_password/`, formDataToSend);
      if (response.data.error) {
        setLoading(false);
        setErrorMsg({ error: true, text: response.data.message });
      } else {
        setLoading(false);
        localStorage.setItem("admin_email", formData.email);
        navigate("/verification");
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <AuthLayout authTitle='Password Recovery' authPara='Enter your email address to receive a verification code.' backOption={true}>
        <form>
          <CustomInput label='Email Address' required id='userEmail' type='email' placeholder='Enter Your Email Address' labelClass='mainLabel' inputClass='mainInput' onChange={(event) => {
            setFormData({ ...formData, email: event.target.value })
            setErrorMsg({ error: false, text: '' });
          }} />
          {errorMsg.error === true && (
            <div className="col-12">
              <p className="secondaryText">{errorMsg.text}</p>
            </div>
          )}
          <div className="row mt-4 ">
            <div className="col-12 text-center">
              {
                loading ? (
                  <button class="primaryButton customButton" onClick={(e) => { e.preventDefault() }}>
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Continue"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>

        </form>

      </AuthLayout>
    </>
  )
}



export default ForgetPassword
