import React, { useEffect, useState } from 'react'
import CustomTable from '../../CustomTable';
import { Link } from 'react-router-dom';
import BASEURL from '../../../Config/global';
import axios from 'axios';
import { Accordion, Form, ListGroup } from 'react-bootstrap';


const CategoryTable = ({ config, selectedPromoData, handleSelectedData }) => {

    const [gridData, setGridData] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getDataByApi();
    }, [config]);

    const selectCategories = (category) => {
        const array = []
        category.map((item) => {
            array.push({
                "category": item?.id,
                "subcategories": selectSubCategories(item?.category_data || item?.subcategories)
            })
        })
        return array;
    }

    const selectSubCategories = (sub_category) => {
        const array = [];
        sub_category?.length > 0 && sub_category?.map((item, index) => {
            array.push(item?.id);
        })
        return array;
    }

    const getDataByApi = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${BASEURL + config['url']}`
            );
            if (response.data.data) {
                let selectedCategoryAndSubCategoryItem = [];
                const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence });
                if (selectedPromoData?.is_new) {
                    // console.log(sortedData)
                    selectedCategoryAndSubCategoryItem = selectCategories(sortedData);
                    setCheckedItems(selectedCategoryAndSubCategoryItem);
                    handleSelectedData(selectedCategoryAndSubCategoryItem, config.name);
                    // console.log(selectedCategoryAndSubCategoryItem)
                } else {
                    if (selectedPromoData['cats_and_subcats'][config.name]) {
                        setCheckedItems(selectedPromoData['cats_and_subcats'][config.name])
                        handleSelectedData(selectedPromoData['cats_and_subcats'][config.name], config.name);
                    } else {
                        sortedData.map((item) => {
                            selectedCategoryAndSubCategoryItem.push({
                                "category": item?.id,
                                "subcategories": selectSubCategories(item?.category_data || item?.subcategories)
                            })
                        })
                        setCheckedItems(selectedCategoryAndSubCategoryItem);
                        handleSelectedData(selectedCategoryAndSubCategoryItem, config.name);
                    }
                    // console.log(selectedPromoData['cats_and_subcats'][config.name])
                }
                setGridData(sortedData);
                setLoading(false);
                // console.log(sortedData);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCheckboxChange = (id, index, sub_id) => {
        let newCheckedItems = [...checkedItems];
        if (!sub_id) {
            if (newCheckedItems?.[index]?.['category']) {
                newCheckedItems[index]['category'] = null;
                newCheckedItems[index]['subcategories'] = [];
            } else {
                newCheckedItems[index] = {};
                newCheckedItems[index]['category'] = id;
                newCheckedItems[index]['subcategories'] = [];
            }
        } else {
            let sub_category = newCheckedItems[index]['subcategories']
            if (newCheckedItems[index]['subcategories'].includes(sub_id)) {
                newCheckedItems[index]['subcategories'] = sub_category.filter(id => id !== sub_id);
            } else {
                newCheckedItems[index]['subcategories'].push(sub_id);
            }
        }
        setCheckedItems(newCheckedItems);
        handleSelectedData(newCheckedItems, config.name);
    };

    if (loading) {
        return (
            <div className="center-align">
                <div className="spinner-border" role="status"></div>
            </div>
        )
    }

    return (
        <div className="row mb-3">
            <div className="col-12 col-xl-8 col-xxl-6 mx-auto">
                <Accordion>
                    {gridData?.length > 0 && gridData.map((item, index) => (
                        <div key={item?.id}>
                            <div className='customCheckBox'>
                                <Form.Check
                                    type="checkbox"
                                    label={item?.name}
                                    id={`checkbox-main${item.name.trim('') + index}`}
                                    key={`checkbox-main${item.name.trim('') + index}`}
                                    checked={checkedItems?.[index]?.['category'] || ''}
                                    onChange={() => handleCheckboxChange(item.id, index)}
                                />
                            </div>
                            <Accordion.Item className={`customAccordion ${item?.category_data?.length == 0 || item?.subcategories?.length == 0 ? 'no-accordian-dropdown' : ''} `} key={`Accordion`} eventKey={index}>
                                <Accordion.Header></Accordion.Header>
                                <Accordion.Body className='customAccordion pt-2'>
                                    {((item?.category_data?.length > 0 || item?.subcategories?.length > 0) && (
                                        <>
                                            {[...(item?.category_data || []),  // Default to empty array if null/undefined
                                                ...(item?.subcategories || [])    // Default to empty array if null/undefined
                                            ].map((sub_item, sub_index) => (
                                                <ListGroup className='customList ps-4 py-1' key={sub_item?.id}>
                                                    <div className='text-white'>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={sub_item?.name}
                                                            id={`checkbox-main${sub_item?.name.trim('') + sub_index}`}
                                                            checked={checkedItems?.[index]?.['subcategories']?.includes(sub_item.id) || false}
                                                            onChange={() => handleCheckboxChange(item.id, index, sub_item.id)}
                                                        />
                                                    </div>
                                                </ListGroup>
                                            ))}
                                        </>
                                    ))}
                                </Accordion.Body>


                            </Accordion.Item>
                        </div>

                    ))}
                </Accordion>
            </div>
        </div>
    )
}

export default CategoryTable
