import { useParams } from "react-router";
import CategoryComponent from "../CategoryComponent";

const EditStoriesCategory = () => {
  const { id } = useParams();
  return (
    <>
      <CategoryComponent
        name={"Story"}
        baseAPI={`/api/stories/storiescategories/${id}`}
      />
    </>
  );
};
export default EditStoriesCategory;
