import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import BackButton from '../../../Components/BackButton'
import CategoryTable from '../../../Components/Displays/Tables/CategoryTable'
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BASEURL from '../../../Config/global';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { questionModal, successModal } from '../../../Components/CustomModal';
import { postRequest } from '../../../Util/requests';

const CategoryList = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const promoData = location?.state; // Access the passed state
    // console.log(promoData)
    const LocalData = {}
    const [selectedPromoData, setSelectedPromoData] = useState({});
    const [stateHandling, setStateHandling] = useState({ error: false, loading: true, customLoader: false, msg: '', data: {}, showModal: false });

    useEffect(() => {
        if (promoData?.id) {
            getList(promoData?.id)
        }
    }, [promoData?.id]);

    const handleSelectedData = (data, name) => {
        LocalData[name] = data;
    }

    const updateList = async () => {
        try {
            const body = {
                "promo_id": id,
                "is_new": false,
                "cats_and_subcats": LocalData
            }
            const result = await questionModal.fire({
                title: "Do you want to Update this Promo List?",
                confirmButtonText: "Update",
            });
            if (!result.isConfirmed) {
                return;
            }
            setStateHandling({ ...stateHandling, 'loading': true })
            // console.log(body)
            const response = await postRequest(`/api/user/coupon_cat_sub_cat`, body)
            // const response = await axios.post(
            //     `${BASEURL}/api/user/coupon_cat_sub_cat`,
            //     body
            // );
            if (!response?.data?.error) {
                successModal.fire({
                    text: "Promo List Updated Successfully",
                    confirmButtonText: "Continue",
                });
                setStateHandling({ ...stateHandling, 'loading': false })
                navigate(-1)
                // getList(promoData?.id);
            } else {
                setStateHandling({ ...stateHandling, 'data': response?.data, 'showModal': true })
            }
        } catch (error) {
            console.log(error)
        }

    }

    const getList = async (id) => {
        try {
            const response = await axios.get(`${BASEURL}/api/user/coupon_cat_sub_cat?promo_id=${id}`);
            console.log(response)
            // const data = response?.data?.data;
            if (!response?.data?.error) {
                setSelectedPromoData(response?.data?.data)
            } else {
                setSelectedPromoData(response?.data?.data)
                // setStateHandling({ ...stateHandling, 'data': response?.data, 'showModal': true })
                // console.log(response)
            }
            setStateHandling({ ...stateHandling, 'loading': false })
        } catch (error) {
            console.log(error)
        }
    }

    // if (stateHandling?.loading) {
    //     return (
    //         <div className="center-align">
    //             <div className="spinner-border" role="status"></div>
    //         </div>
    //     )
    // }

    return (
        <>
            <DashboardLayout>
                {
                    stateHandling?.loading ? (
                        <div className="center-align">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    ) : (
                        <div className="container-fluid">
                            <div className="row mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-8">
                                            <h2 className="mainTitle mb-5">
                                                <BackButton />
                                                {promoData?.title}
                                            </h2>
                                        </div>
                                        <div className="col-4 text-end">
                                            <button onClick={updateList} className="customButton primaryButton me-3">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <Tabs defaultActiveKey="sound" className="customTabs mb-4">
                                                {Number(selectedPromoData?.promo_id) && ConfigData.map((config, index) =>
                                                    <Tab key={index} eventKey={config?.name} title={config?.title}>
                                                        <CategoryTable
                                                            key={index}
                                                            config={config}
                                                            selectedPromoData={selectedPromoData}
                                                            handleSelectedData={handleSelectedData}
                                                        />
                                                    </Tab>
                                                )}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </DashboardLayout>

            {/* copied modal */}
            <Modal
                show={stateHandling?.showModal}
                centered
                className="swap-modal promo-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end ms-auto">
                        <button
                            className="closeButton notButton ms-auto"
                            onClick={() => { navigate(-1) }}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="cross-icon"
                                color="white"
                            />
                        </button>
                    </div>
                    <div className="p-3 w-100">
                        <div>
                            <h2 className='mb-4'>Request Failed:</h2>
                            <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '5px', color: 'black' }}>
                                {JSON.stringify(stateHandling?.data, null, 2)}
                            </pre>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default CategoryList

const ConfigData = [
    {
        "title": "Sound",
        "name": "sound",
        "url": "/api/sounds/soundscategories"
    },
    {
        "title": "Relax & Rest",
        "name": "meditation",
        "url": "/api/meditation/meditationcategories"
    },
    {
        "title": "Stories",
        "name": "stories",
        "url": "/api/stories/storiescategories"
    },
    {
        "title": "Article",
        "name": "article",
        "url": "/api/articles/articlecategories"
    },
    {
        "title": "Featured Mixes",
        "name": "featured_mixes",
        "url": "/api/sounds/mixFeatureCategories"
    },
];


const CheckedData = {
    "is_new": true,
    "promo_id": 880,
    "sound": [
        {
            "category": 162,
            "subcategories": [
                294,
                288
            ]
        },
        {
            "category": 165,
            "subcategories": []
        },
        {
            "category": 163,
            "subcategories": []
        },
        {
            "category": 164,
            "subcategories": []
        },
        {
            "category": 161,
            "subcategories": []
        },
        {
            "category": 166,
            "subcategories": []
        },
        {
            "category": 167,
            "subcategories": []
        },
        {
            "category": 168,
            "subcategories": []
        },
        {
            "category": 289,
            "subcategories": []
        },
        {
            "category": 291,
            "subcategories": []
        },
        {
            "category": 292,
            "subcategories": []
        },
        {
            "category": 293,
            "subcategories": []
        },
        {
            "category": 295,
            "subcategories": []
        },
        {
            "category": 302,
            "subcategories": []
        },
        {
            "category": 312,
            "subcategories": []
        },
        {
            "category": 313,
            "subcategories": []
        },
        {
            "category": 314,
            "subcategories": []
        },
        {
            "category": 319,
            "subcategories": []
        },
        {
            "category": 320,
            "subcategories": []
        },
        {
            "category": 321,
            "subcategories": []
        },
        {
            "category": 322,
            "subcategories": []
        },
        {
            "category": 323,
            "subcategories": [
                324
            ]
        },
        {
            "category": 325,
            "subcategories": [
                326
            ]
        },
        {
            "category": 327,
            "subcategories": []
        }
    ],
    "meditation": [
        {
            "category": 59,
            "subcategories": [
                78,
                71
            ]
        },
        {
            "category": 20,
            "subcategories": []
        },
        {
            "category": 22,
            "subcategories": []
        },
        {
            "category": 23,
            "subcategories": []
        },
        {
            "category": 24,
            "subcategories": []
        },
        {
            "category": 25,
            "subcategories": []
        },
        {
            "category": 26,
            "subcategories": []
        },
        {
            "category": 27,
            "subcategories": []
        },
        {
            "category": 28,
            "subcategories": []
        },
        {
            "category": 39,
            "subcategories": []
        },
        {
            "category": 19,
            "subcategories": []
        },
        {
            "category": 81,
            "subcategories": []
        },
        {
            "category": 83,
            "subcategories": []
        },
        {
            "category": 94,
            "subcategories": [
                95
            ]
        },
        {
            "category": 96,
            "subcategories": [
                97
            ]
        }
    ],
    "stories": [
        {
            "category": 15,
            "subcategories": [
                77
            ]
        },
        {
            "category": 33,
            "subcategories": []
        },
        {
            "category": 70,
            "subcategories": []
        },
        {
            "category": 73,
            "subcategories": []
        },
        {
            "category": 78,
            "subcategories": [
                79
            ]
        },
        {
            "category": 80,
            "subcategories": [
                81
            ]
        }
    ],
    "article": [
        {
            "category": 21,
            "subcategories": [
                83,
                89
            ]
        },
        {
            "category": 30,
            "subcategories": []
        },
        {
            "category": 23,
            "subcategories": []
        },
        {
            "category": 25,
            "subcategories": []
        },
        {
            "category": 28,
            "subcategories": []
        },
        {
            "category": 29,
            "subcategories": []
        },
        {
            "category": 20,
            "subcategories": []
        },
        {
            "category": 31,
            "subcategories": []
        },
        {
            "category": 32,
            "subcategories": []
        },
        {
            "category": 45,
            "subcategories": []
        },
        {
            "category": 76,
            "subcategories": []
        },
        {
            "category": 77,
            "subcategories": []
        },
        {
            "category": 78,
            "subcategories": []
        },
        {
            "category": 79,
            "subcategories": [
                82
            ]
        },
        {
            "category": 85,
            "subcategories": [
                86
            ]
        },
        {
            "category": 87,
            "subcategories": [
                88
            ]
        }
    ]
}