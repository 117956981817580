import { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "./../../Components/CustomTable";
import { placeholderImage } from "../../Assets/images";

import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import CustomPagination from "../../Components/CustomPagination";
import CustomLoader from "../../Components/CustomLoader";

import { fetchData } from "../../Services/Sound";
import "./style.css";
import BASEURL from "../../Config/global";
import { PermissionsContext } from "../../Layout/PermissionContext";
import { disabledButtonByPermission } from "../../Components/Permissions";
import { deleteRequest } from "../../Util/requests";

const SoundManagement = () => {
  const { permissions } = useContext(PermissionsContext)
  const navigate = useNavigate();
  const sortRef = useRef(null);

  const [data, setData] = useState([]);

  const [enteries, setEnteries] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [updateData, setUpdateData] = useState(false);
  const [swapId, setSwapId] = useState(null);
  const [swapRecordId, setSwapRecordId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({ error: true, text: '' });

  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (currentPage != undefined) {
      setOffset(currentPage);
    }
  }, [currentPage])
  
  useEffect(() => {
    document.title = "Relax Scofa | Sound Management";
  }, []);

  useEffect(() => {

    (async function () {
      try {
        const response = await fetchData(
          enteries,
          offset,
          dateFrom,
          dateTo,
          filterSearchValue
        );
        console.log(response.data.data);
        setData(response.data.data);
        setTotalCount(response.data.count);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [enteries, filterSearchValue, dateTo, updateData, offset]);

  const deleteSoundAction = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to hide ${name} Sound?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteSound(id, name);
        }
      });
  };

  const deleteSound = async (id, name) => {
    setLoader(true);
    try {
      const response = await deleteRequest(`/api/sounds/${id}`);
      console.log(response.data);
      if (response.data.error === false) {
        successModal.fire({
          text: `${name} Sound has been hidden`,
          confirmButtonText: "Continue",
        });
        setUpdateData(!updateData);
        setLoader(false);
      }
    } catch (error) {
      console.log("error =======>   ", error);
      setUpdateData(!updateData);
      setLoader(false);
    }
  };

  const soundHeader = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "type",
      title: "Type",
    },
    {
      key: "category",
      title: "Category",
    },
    {
      key: "subcategory",
      title: "Sub Category",
    },
    {
      key: "underagecontent",
      title: "Under age content",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const swipModal = (id, recordId) => {
    setError({ error: false, text: '' })
    setSwapId(id);
    // setSwapRecordId(recordId);
    setShowModal(true);
  }

  const setSorting = async () => {
    setError({ error: false, text: '' });
    let recordId = parseInt(sortRef.current.value, 10);
    if (recordId != '') {
      if (sortRef.current.value < data.length && sortRef.current.value > 0) {
        console.log(data[swapId - 1].id, data[recordId - 1].id);
        setShowModal(false);
        const formDataToSend = new FormData();
        formDataToSend.append("sound_id_1", data[swapId - 1].id);
        formDataToSend.append("sound_id_2", data[recordId - 1].id);
        try {
          const response = await axios.put(`${BASEURL}/api/sounds/swap-sounds/`, formDataToSend);
          // console.log(response);
          setLoader(true);
          (async function () {
            try {
              const response = await fetchData(
                enteries,
                offset,
                dateFrom,
                dateTo,
                filterSearchValue
              );
              // console.log(response.data.data);
              setData(response.data.data);
              setTotalCount(response.data.count);
              setLoader(false);
            } catch (error) {
              console.error(error);
            }
          })();
        } catch (error) {
          console.log(error);
        }
      } else {
        setError({ error: true, text: 'Please Enter Correct No' });
      }
    } else {
      setError({ error: true, text: 'Please Enter No' });
    }
  }
  const modulesWisePermission = (condition) =>{
    if(!permissions){
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }

  const handleChangeEntries = (no) => {
    setOffset(0)
    setEnteries(no)
  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-lg-8 mb-2">
              <h2 className="mainTitle">Sound </h2>
            </div>
            <div className="col-lg-4 text-end mb-2">
              <Link
                to={"/sound-management/add-sound"}
                className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
              >
                Add Sound
              </Link>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                // dateFilter={true}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomTable headers={soundHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <button type="button" className="btn lightColor outline-0 border-0" onClick={() => swipModal(index + 1, item.id)}>{index + 1}</button>
                        </td>
                        <td className="text-capitalize">
                          <img
                            src={
                              item.thumbnail
                                ? `${item.thumbnail}`
                                : // ? `${image_url+item.thumbnail}`
                                placeholderImage
                            }
                            alt="thumbnail"
                            className="thumbnail"
                          />
                          {item.title}
                        </td>
                        <td>{item.premium ? "Premium" : "Free"}</td>
                        <td>{item.soundscategoriesname}</td>
                        <td>{item?.subcategory_obj?.child_subcategory_name}</td>
                        <td>{item?.child_content ? "YES" : "NO" }</td>

                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle
                              variant="transparent"
                              className="notButton classicToggle"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="tableDropdownMenu"
                            >
                              <Link
                                to={`/sound-management/sound-details/${item.id}`}
                                className={`tableAction ${modulesWisePermission('can_view')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="tableActionIcon"
                                />
                                View
                              </Link>
                              <Link
                                to={`/sound-management/edit-sound/${item.id}`}
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="tableActionIcon"
                                />
                                Edit
                              </Link>
                              <button
                                className={`tableAction ${modulesWisePermission('can_delete')}`}
                                onClick={() => {
                                  deleteSoundAction(item.id, item.title);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="tableActionIcon"
                                />
                                Hide
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>

      {loader && <CustomLoader />}

      <Modal
        show={showModal}
        centered
        className="swap-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="pb-5 w-100 d-flex flex-column align-items-center">
            <div className="text-end ms-auto">
              <button
                className="closeButton notButton ms-auto"
                onClick={() => { setShowModal(false); }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="cross-icon"
                  color="white"
                />
              </button>
            </div>
            <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '500px' }}>
              <div className="d-flex align-items-center mt-3 pt-3">
                <h2 className="mb-0 me-4">Record <b className="mx-2">{swapId}</b> swap with </h2>
                <input type="number" ref={sortRef} onChange={() => setError({ error: false, text: '' })} />
              </div>
              {error?.error && <small className="text-danger mt-3">{error.text}</small>}
            </div>
            <div className="mt-3 pt-3 d-flex gap-3 justify-content-center">
              <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={setSorting}>
                <span className=''>Update</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default SoundManagement;
