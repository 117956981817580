import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import "./style.css";


import { AuthLayout } from '../../Layout/AuthLayout'
import CustomInput from "../../Components/CustomInput"
import CustomButton from '../../Components/CustomButton';
import BASEURL from '../../Config/global';
import axios from 'axios';


const VerificationPassword = () => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: false, text: "" });

  useEffect(() => {
    document.title = 'Relax Scofa | Password Recovery';
  }, [])


  const handleClick = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    const userEmail = localStorage.getItem("admin_email");
    if (!formData.otp || formData.otp === "") {
      setErrorMsg({ error: true, text: "Please enter OTP" })
      return;
    }
    setLoading(true)
    formDataToSend.append("email", userEmail?.trim());
    formDataToSend.append("otp", formData.otp);


    try {
      const response = await axios.post(`${BASEURL}/api/user/otp_verify/`, formDataToSend);
      if (response.data.error) {
        setLoading(false);
        setErrorMsg({ error: true, text: response.data.message });
      } else {
        setLoading(false);
        navigate("/reset-password");
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <AuthLayout>
        <form>
          <CustomInput
            label='Verification Code'
            required
            id='verificationCode'
            type='number'
            placeholder='Enter Verification Code'
            labelClass='mainLabel'
            inputClass='mainInput'
            onChange={(event) => {
              setFormData({ ...formData, otp: event.target.value })
              setErrorMsg({ error: false, text: "" });

            }}
          />
          {errorMsg.error === true && (
            <div className="col-12">
              <p className="secondaryText">{errorMsg.text}</p>
            </div>
          )}
          <div className="row mt-4 ">
            <div className="col-12 text-center">
              {
                loading ? (
                  <button class="primaryButton customButton" onClick={(e) => { e.preventDefault() }}>
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Continue"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>

        </form>
      </AuthLayout>
    </>
  )
}



export default VerificationPassword