import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import CustomLoader from "../../Components/CustomLoader";
import { questionModal, successModal } from "../../Components/CustomModal";
import "./style.css";
import BASEURL from "../../Config/global";
import moment from "moment";
import { patchRequest } from "../../Util/requests";

const EditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [sliderList, setSliderList] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    fetchAssetData()
  }, [])

  async function fetchAssetData() {
    try {

      const response = await axios.get(
        `${BASEURL}/api/user/upload_asset/`
      );
      if (response.data.error !== true) {
        console.log(response.data.data);
        setAssetData(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSlierByApi();
  }, []);

  const getSlierByApi = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/infoboxes`
      );
      setSliderList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
        setFormData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);



  const handleChange = (event) => {
    setLoginError({ error: false, text: "" });
    const { name, value } = event.target;
    if (name === 'code' || name === 'trial_period' || name === 'term_in_days' || name === 'number_of_uses_allowed' || name === 'propagation_code' || name === 'propagation_trial_period' || name === 'propagation_term_in_days' || name === 'price' || name === 'propagation_price') {

      if (name === 'code' || name === 'propagation_code') {
        if (value.length <= 20) {
          setFormData({ ...formData, [name]: value });
        } else {
          setLoginError({ error: true, text: "Promo code should be less than 20 digits." });
        }
      }

      if (value >= 0) {
        if (name === 'trial_period' || name === 'term_in_days' || name === 'propagation_trial_period' || name === 'propagation_term_in_days') {
          if (value.length <= 4) {
            setFormData({ ...formData, [name]: value });
          } else {
            setLoginError({ error: true, text: `${name === "trial_period" ? "Trial Period" : "Term in Days"} should be less than 4 digits.` });
          }
        }

        if (name === 'number_of_uses_allowed') {
          if (value.length <= 6) {
            setFormData({ ...formData, [name]: value });
          } else {
            setLoginError({ error: true, text: `Uses Allowed should be less than 6 digits.` });
          }
        }

        if (name === 'price' || name === 'propagation_price') {
          setFormData({ ...formData, [name]: value });
        }
      }

    } else {
      console.log("chec", [name], value)
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmitConfirmation = (event) => {
    event.preventDefault();
    questionModal
      .fire({
        title: `Do you want to update this Subscription?`,
        confirmButtonText: "Yes",
      }).then(async result => {
        if (result.isConfirmed) {
          await handleSubmit()
        }
      })
  }
  const handleSubmit = async () => {
    try {
      // event.preventDefault();
      if (formData.is_propagation_allowed && Number(formData?.propagation_price) === 0) {
        setLoginError({ error: true, text: "Propagation Price cannot be zero" });
        return
      }
      setLoader(true);
     
      const body = {
        "title": formData.title,
        "price": formData.price,
        "interval": formData.interval,
        "description": formData.description,
        "primary_package_user": (formData?.primary_package_user?.id || formData?.primary_package_user?.id !== NaN) ? formData?.primary_package_user?.id : null,
        "household_package_user": formData.household_package_user?.id || formData?.household_package_user?.id !== NaN ? formData.household_package_user?.id : null,
        "promo_upload_asset":formData?.promo_upload_asset?.id,
        "is_propagation_allowed": formData.is_propagation_allowed,
        "propagation_title": formData.propagation_title,
        "propagation_description": formData.propagation_description,
        "propagation_code_description": formData.propagation_code_description,
        "propagation_price": Number(formData.propagation_price),
        "propagation_expiry_date": formData.propagation_is_expiry_unlimited ? null : formData.propagation_expiry_date,
        "propagation_is_expiry_unlimited": formData.propagation_is_expiry_unlimited,
        "propagation_subscription_type": formData.propagation_subscription_type,
        "propagation_duration_type": formData.propagation_duration_type,
        "propagation_trial_period": Number(formData.propagation_trial_period),
        "propagation_term_in_days": Number(formData.propagation_term_in_days),
        "propagation_primary_promo_user": Number(formData?.propagation_primary_promo_user?.id),
        "propagation_household_promo_user": Number(formData?.propagation_household_promo_user?.id),
        "promo_propagation_asset": formData?.promo_propagation_asset?.id,
        "propagation_is_propagation_allowed":formData?.propagation_is_propagation_allowed,
    }
      const response = await patchRequest(`/api/user/packages/${id}`, body)
    
      if (!response.data.error) {
        successModal.fire({
          text: "Subscription Edited Successfully",
          confirmButtonText: "Continue",
        });
        setLoader(false);
        navigate("/subscription-management");
      } else {
        setLoader(false);
      }
      // Handle the response data here, e.g., log it or use it in your application.
      console.log("Response data:", response.data);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);

      // You can also provide specific error messages or actions based on the error type:
      if (error.response) {
        // The request was made, but the server responded with an error.
        console.error("Server error:", error.response.data);
      } else if (error.request) {
        // The request was made, but there was no response from the server.
        console.error("No response received from the server.");
      } else {
        // Something else went wrong.
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Edit Subscription
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <form onSubmit={handleSubmitConfirmation}>
                <div className="row mb-3">

                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Title"
                      labelClass="mainLabel"
                      required
                      type="text"
                      name="title"
                      value={formData.title || ""}
                      placeholder="Enter Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Price"
                      labelClass="mainLabel"
                      required
                      type="number"
                      name="price"
                      value={formData.price || ""}
                      placeholder="Enter Price"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>



                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Modified Date"
                      labelClass="mainLabel"
                      type="text"
                      name="updated"
                      value={moment(formData.updated).format('MM/DD/YYYY')}
                      inputClass="mainInput"
                      onChange={handleChange}
                      disabled={true}
                    />
                  </div>


                  <div className="col-lg-3 mb-2">
                    <p className="mainLabel mb-4">Subscription Type*</p>
                    <label className="secondaryText me-3">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"month"}
                        className="me-1"
                        checked={formData?.interval == "month" ? true : false}
                        onChange={handleChange}
                      />
                      Monthly
                    </label>
                    <label className="secondaryText">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"year"}
                        className="me-1"
                        checked={formData?.interval == "year" ? true : false}
                        onChange={handleChange}
                      />
                      Yearly
                    </label>
                  </div>

                  <div className="col-lg-12 mb-2">
                    <div className="inputWrapper">
                      <label className="mainLabel">Description*</label>
                      <textarea
                        rows="5"
                        placeholder="Enter Description"
                        required=""
                        name="description"
                        className="mainInput"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea></div>
                  </div>

                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel">Primary User</p>
                    {sliderList && (
                      <select
                        name="primary_package_user"
                        id="primary_package_user"
                        className="mainInput w-100"
                        value={formData?.primary_package_user?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {sliderList.length > 0 && sliderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.slider_name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel">Household User</p>
                    {sliderList && (
                      <select
                        name="household_package_user"
                        id="household_package_user"
                        className="mainInput w-100"
                        value={formData?.household_package_user?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {sliderList.length > 0 && sliderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.slider_name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-lg-3 mb-2 ">
                    <p className="mainLabel">Select Subscription Asset</p>
                    {assetData && (
                      <select
                        name="promo_upload_asset"
                        id="promo_upload_asset"
                        className="mainInput w-100"
                        required
                        value={formData?.promo_upload_asset?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {assetData.length > 0 && assetData.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="offset-lg-0 col-lg-3 mb-2">
                    <p className="mainLabel mb-4 mt-5">Propagation</p>
                    <label className="secondaryText me-3">
                      <input
                        type="radio"
                        name="is_propagation_allowed"
                        value={true}
                        id="is_propagation_allowed_true"
                        checked={formData.is_propagation_allowed}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({ ...formData, [name]: true })
                        }}
                      />
                      <label htmlFor="is_propagation_allowed_true" className="ms-2">Enable</label>
                    </label>
                    <label className="secondaryText">
                      <input
                        type="radio"
                        name="is_propagation_allowed"
                        value={false}
                        id="is_propagation_allowed_false"
                        checked={!formData.is_propagation_allowed}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({ ...formData, [name]: false })
                        }}
                      />
                      <label htmlFor="is_propagation_allowed_false" className="ms-2">Disable</label>
                    </label>
                  </div>
                  {
                                    formData.is_propagation_allowed ?
                                        <>

                                            <div className="col-12"></div>

                                            <hr />
                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Propagation Title"
                                                    requiredHint="Subscription Page"
                                                    labelClass="mainLabel"
                                                    required
                                                    type="text"
                                                    name="propagation_title"
                                                    value={formData.propagation_title || ""}
                                                    placeholder="Enter Title"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg mb-2">
                                                <CustomInput
                                                    label="Propagation Description"
                                                    requiredHint="Subscription Page"
                                                    labelClass="mainLabel"
                                                    required
                                                    type="text"
                                                    name="propagation_description"
                                                    value={formData.propagation_description || ""}
                                                    placeholder="Enter Description"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg mb-2">
                                                <CustomInput
                                                    label="Promo Code Description"
                                                    requiredHint="Promo Code Popup"
                                                    labelClass="mainLabel"
                                                    type="text"
                                                    name="propagation_code_description"
                                                    value={formData.propagation_code_description || ""}
                                                    placeholder="Enter Code Description"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="col-12"></div>

                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            <div className="offset-lg-0 col-lg-3 mb-2">
                                                <p className="mainLabel mb-4">Propagation</p>
                                                <label className="secondaryText me-3">
                                                    <input
                                                        type="radio"
                                                        name="propagation_is_propagation_allowed"
                                                        value={true}
                                                        id="propagation_is_propagation_allowed_true"
                                                        checked={formData.propagation_is_propagation_allowed}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: true })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_is_propagation_allowed_true" className="ms-2">Enable</label>
                                                </label>
                                                <label className="secondaryText">
                                                    <input
                                                        type="radio"
                                                        name="propagation_is_propagation_allowed"
                                                        value={false}
                                                        id="propagation_is_propagation_allowed_false"
                                                        checked={!formData.propagation_is_propagation_allowed}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: false })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_is_propagation_allowed_false" className="ms-2">Disable</label>
                                                </label>
                                            </div>
                                            <div className="col-lg mb-2">
                                                <CustomInput
                                                    label="Propagation Price"
                                                    labelClass="mainLabel"
                                                    required
                                                    type="number"
                                                    name="propagation_price"
                                                    value={formData.propagation_price || ""}
                                                    placeholder="Enter Price"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg mb-2">
                                                <div className="position-relative coupon-expires">
                                                    <label className="mainLabel">Propagation Expires <span style={{ fontSize: '10px' }}>(Check for unlimited)</span>*</label>
                                                    <CustomInput
                                                        type="date"
                                                        name="propagation_expiry_date"
                                                        value={formData.propagation_expiry_date || ""}
                                                        inputClass="mainInput"
                                                        onChange={handleChange}
                                                        min={minDate}
                                                    />
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={formData.propagation_is_expiry_unlimited}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, 'propagation_is_expiry_unlimited': e.target.checked });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg mb-2">
                                                <p className="mainLabel mb-4">Propagation Subscription Type</p>
                                                <label className="secondaryText me-3">
                                                    <input
                                                        type="radio"
                                                        name="propagation_subscription_type"
                                                        id="month"
                                                        value="month"
                                                        checked={formData.propagation_subscription_type === 'month'}
                                                        className="me-1"
                                                        onChange={handleChange}
                                                    />
                                                    Monthly
                                                </label>
                                                <label className="secondaryText">
                                                    <input
                                                        type="radio"
                                                        name="propagation_subscription_type"
                                                        id="year"
                                                        value="year"
                                                        checked={formData.propagation_subscription_type === 'year'}
                                                        className="me-1"
                                                        onChange={handleChange}
                                                    />
                                                    Yearly
                                                </label>
                                            </div>

                                            <div className="col-12"></div>

                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            <div className="col-lg mb-2">
                                                <p className="mainLabel mb-4">Select Propagation Duration Type</p>
                                                <label className="secondaryText me-3">
                                                    <input
                                                        type="radio"
                                                        name="propagation_duration_type"
                                                        value="trial_period"
                                                        id="propagation_trial_period"
                                                        checked={formData.propagation_duration_type === 'trial_period'}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: value, 'propagation_term_in_days': '' })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_trial_period" className="ms-2">Trial Period</label>
                                                </label>
                                                <label className="secondaryText">
                                                    <input
                                                        type="radio"
                                                        name="propagation_duration_type"
                                                        value="term_in_days"
                                                        id="propagation_term_in_days"
                                                        checked={formData.propagation_duration_type === 'term_in_days'}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: value, 'propagation_trial_period': '' })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_term_in_days" className="ms-2">Term in Days</label>
                                                </label>
                                            </div>
                                            {
                                                formData.propagation_duration_type === 'trial_period' ?
                                                    <>
                                                        <div className="col-lg mb-2">
                                                            <CustomInput
                                                                label="Propagation Trial Period"
                                                                labelClass="mainLabel"
                                                                type="number"
                                                                name="propagation_trial_period"
                                                                value={formData.propagation_trial_period || ""}
                                                                placeholder="Trial Period"
                                                                inputClass="mainInput"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-lg mb-2">
                                                            <CustomInput
                                                                label="Propagation Term in Days"
                                                                labelClass="mainLabel"
                                                                type="number"
                                                                name="propagation_term_in_days"
                                                                value={formData.propagation_term_in_days || ""}
                                                                placeholder="Term in Days"
                                                                inputClass="mainInput"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </>
                                            }

                                            <div className="col-lg mb-2">
                                                <p className="mainLabel">Propagation Primary Info Slider</p>
                                                {sliderList && (
                                                    <select
                                                        name="propagation_primary_promo_user"
                                                        id="propagation_primary_promo_user"
                                                        className="mainInput w-100"
                                                        required
                                                        value={formData?.propagation_primary_promo_user?.id || ""}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({
                                                                ...formData, [name]: {
                                                                    ...formData[name],
                                                                    "id": Number(value)
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <option value={null}> Select </option>
                                                        {sliderList.length > 0 && sliderList.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.slider_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>

                                            <div className="col-lg mb-2">
                                                <p className="mainLabel">Propagation Household Info Slider</p>
                                                {sliderList && (
                                                    <select
                                                        name="propagation_household_promo_user"
                                                        id="propagation_household_promo_user"
                                                        className="mainInput w-100"
                                                        required
                                                        value={formData?.propagation_household_promo_user?.id || ""}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({
                                                                ...formData, [name]: {
                                                                    ...formData[name],
                                                                    "id": Number(value)
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <option value={null}> Select </option>
                                                        {sliderList.length > 0 && sliderList.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.slider_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>


                                            <div className="col-12 mb-2"></div>
                                            <br/>
                                            <br/>

                                            <div className="col-lg-3 mb-2">
                                                <p className="mainLabel">Select Propagation Asset</p>
                                                {assetData && (
                                                    <select
                                                        name="promo_propagation_asset"
                                                        id="promo_propagation_asset"
                                                        className="mainInput w-100"
                                                        required
                                                        value={formData?.promo_propagation_asset?.id || ""}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({
                                                                ...formData, [name]: {
                                                                    ...formData[name],
                                                                    "id": Number(value)
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <option value={null}> Select </option>
                                                        {assetData.length > 0 && assetData.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>
                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                        </>
                                        :
                                        <></>
                                }
                </div>
                {loginError.error == true && (
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="secondaryText">{loginError.text}</p>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12">
                    <CustomButton
                      type="submit"
                      variant="primaryButton"
                      text="Update"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default EditSubscription;
