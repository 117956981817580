import CategoryComponent from "../CategoryComponent";
const AddArticleCategory = () => {
  return (
    <>
      <CategoryComponent
        name={"Article "}
        baseAPI={`/api/articles/articlecategories`}
      />
    </>
  );
};
export default AddArticleCategory;
