import CategoryComponent from "../CategoryComponent";

const AddSoundCategory = () => {
 
  return (
    <>
      <CategoryComponent
        name={"Sound"}
        baseAPI={"/api/sounds/soundscategories"}
      />
    </>
  );
};
export default AddSoundCategory;
