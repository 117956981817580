import { useParams } from "react-router";
import CategoryComponent from "../CategoryComponent";

const EditMeditationCategory = () => {
  const { id } = useParams();
  return (
    <>
      <CategoryComponent
        name={"Relax & Rest"}
        baseAPI={`/api/meditation/meditationcategories/${id}`}
      />

    </>
  );
};
export default EditMeditationCategory;
