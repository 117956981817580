import { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "./../../Components/CustomTable";

import "./styles.css";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import BASEURL from "../../Config/global";
import CustomPagination from "../../Components/CustomPagination";
import { useDebounce } from 'use-debounce';
import AssetPreview from "../../Components/AssetPreview";
import { disabledButtonByPermission } from "../../Components/Permissions";
import { PermissionsContext } from "../../Layout/PermissionContext";
import { deleteRequest } from "../../Util/requests";

const UploadManagement = () => {
  const [data, setData] = useState([]);
  const { permissions } = useContext(PermissionsContext)

  const [enteries, setEnteries] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
 
  const [filterSortedValue, setfilterSortedValue] = useState('lastest');
  const [value] = useDebounce(filterSearchValue, 500)

  useEffect(() => {
    document.title = "Relax Scofa | Upload Management";
  }, []);

  useEffect(() => {
    if (currentPage !== undefined) {
      setOffset(currentPage);
    }
  }, [currentPage])


  useEffect(() => {
    fetchData();
  }, [enteries, offset, filterSortedValue]);

  useEffect(() => {
    fetchData();
  }, [value]);

  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }

  const fetchData = async () => {
    try {

      const response = await axios.get(
        `${BASEURL}/api/misc/asset/?limit=${enteries}&offset=${offset}&search=${filterSearchValue}&sortby=${filterSortedValue}`
      );
      if (response.data.error !== true) {
        setData(response.data.data);
        setTotalCount(response.data.count);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const deleteArticle = async (id) => {
    try {
      const response = await deleteRequest(`/api/misc/asset/${id}`);
      if (response.data.error === false) {
        successModal.fire({
          text: "Upload Deleted Successfully",
          confirmButtonText: "Continue",
        });
        setData(data.filter((item) => item.id !== id));
        Navigate("/upload-management");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteArticleAction = (id) => {
    questionModal
      .fire({
        title: `Do you want to delete this upload?`,
        confirmButtonText: "Yes",
        showClass: {
          popup: `
            user_delete_modal
          `
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteArticle(id);
        }
      });
  };

  const articleHeader = [
    {
      key: "srNO",
      title: "SR NO",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "asset_preview",
      title: "Asset Preview",
    },
    {
      key: "url",
      title: "URL",
    },
   
    {
      key: "actions",
      title: "Actions",
    },
  ];

  

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
    successModal.fire({
      text: "Link copied",
      confirmButtonText: "Continue",
    });
  }
  return (
    <>
      <DashboardLayout class="d-flex flex-column">
        <div className="container-fluid d-flex flex-column flex-grow-1">
          <div className="row mb-3">
            <div className="col-lg-8 mb-2">
              <h2 className="mainTitle">Uploads</h2>
            </div>
            <div className="col-lg-4 text-end mb-2">
              <Link
                to={"/upload-management/add-upload"}
                className={`customButton primaryButton ${modulesWisePermission("can_add")}`}
              >
                Add Upload
              </Link>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-5">
              <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={setEnteries}
               
              />
            </div>
            <div className="col-7 d-flex justify-content-end ">
              <div className="d-flex">

                <CustomFilters
                  filterSearch={true}
                  filterSearchValue={filterSearchValue}
                  setFilterSearchValue={setFilterSearchValue}
                />

              </div>
            </div>
          </div>
          <div className="row d-flex flex-column flex-grow-1">
            <div className="col-12 user-management-table flex-grow-1 position-relative overflow-auto">
              <CustomTable headers={articleHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => {
                      return (
                        (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.title}</td>

                            <td>
                              <AssetPreview type={item?.type} src={item?.asset_url} />
                            </td>
                            <td style={{ maxWidth: '300px', textWrap: 'nowrap', textOverflow:'ellipsis', overflow:'hidden' }}>{item?.asset_url}</td>

                            <td>
                              <Dropdown className="tableDropdown">
                                <Dropdown.Toggle
                                  variant="transparent"
                                  className="notButton classicToggle"
                                >
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  align="end"
                                  className="tableDropdownMenu"
                                >
                                  <button
                                    className="tableAction"
                                    onClick={() => {
                                      handleCopy(item.asset_url);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCopy}
                                      className="tableActionIcon"
                                    />
                                    Copy
                                  </button>

                                  <button
                                    className={`tableAction ${modulesWisePermission("can_delete")}`}
                                    onClick={() => {
                                      deleteArticleAction(item.id);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      className="tableActionIcon"
                                    />
                                    Hide
                                  </button>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      )
                    }
                    )}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default UploadManagement;