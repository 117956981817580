import { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faEdit,
    faEllipsisV,
    faEye,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "./../../Components/CustomTable";
import { placeholderImage } from "../../Assets/images";

import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import CustomPagination from "../../Components/CustomPagination";
import CustomLoader from "../../Components/CustomLoader";

import { fetchData } from "../../Services/Sound";
import "./style.css";
import BASEURL from "../../Config/global";
import { PermissionsContext } from "../../Layout/PermissionContext";
import { disabledButtonByPermission } from "../../Components/Permissions";
import moment from "moment";
import CustomInput from "../../Components/CustomInput";
import { useDebounce } from "use-debounce";

const LogsManagement = () => {
    const { permissions } = useContext(PermissionsContext)
    const navigate = useNavigate();
    const sortRef = useRef(null);
    const [hideFilters,setHideFilters] = useState(false);

    const [data, setData] = useState([]);

    const [enteries, setEnteries] = useState(10);
    const [offset, setOffset] = useState(0);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [filterSearchValue, setFilterSearchValue] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [updateData, setUpdateData] = useState(false);
    const [swapId, setSwapId] = useState(null);
    const [swapRecordId, setSwapRecordId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState({ error: true, text: '' });

    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterSortedValue, setfilterSortedValue] = useState('lastest');
      const [value] = useDebounce(filterSearchValue, 2000)

    useEffect(() => {
        if (currentPage != undefined) {
            setOffset(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        document.title = "Relax Scofa | Sound Management";
    }, []);

    useEffect(() => {
        fetchData()
    }, [enteries, filterSearchValue, dateTo, updateData, offset]);

    async function fetchData() {
        try {
            const response = await axios.get(
                `${BASEURL}/api/logging/audit-logs/?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
            )
            setData(response.data.data);
            setTotalCount(response.data.count);
        } catch (error) {
            console.error(error);
        }
    }

    const soundHeader = [
        {
            key: "id",
            title: "S.No",
        },
        {
            key: "email",
            title: "Email",
        },
        {
            key: "role",
            title: "role",
        },
        {
            key: "details",
            title: "details",
        },
        {
            key: "actions",
            title: "Actions",
        },
        {
            key: "timestamp",
            title: "timestamp",
        },
    ];

  
    
    const modulesWisePermission = (condition) => {
        if (!permissions) {
            return 'd-none'
        }
        return disabledButtonByPermission(permissions, condition)
    }

    const handleChangeEntries = (no) => {
        setOffset(0)
        setEnteries(no)
    }

    return (
        <>
            <DashboardLayout>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-lg-8 mb-2">
                            <h2 className="mainTitle">Logs </h2>
                        </div>
                        <div className="col-lg-4 text-end mb-2">
                            <Link
                                to={"/sound-management/add-sound"}
                                className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                            >
                                Add Sound
                            </Link>
                        </div>
                    </div>
                    <div className="row mb-3">
            <div className="col-5">
            <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
              />
            </div>
            <div className="col-7 d-flex justify-content-end ">
              {/* <div className="d-flex  my-3 gap-4 align-items-start">
                {
                  hideFilters && (
                    <>
                      <div className="d-flex align-items-center ">
                        <label className="mb-2 me-3">From:</label>
                        <CustomInput
                          type="date"
                          name="title"
                          value={dateFrom}
                          placeholder="Enter Title"
                          inputClass="filterInput searchInput"
                          onChange={(e)=>setDateFrom(e.target.value)}
                          min="1900-01-01" 
                          max="2099-12-31"
                        />
                      </div>
                      <div className="ms-4 d-flex align-items-center">
                        <label className="mb-2 me-3">To:</label>
                        <CustomInput
                          type="date"
                          name="title"
                          value={dateTo}
                          placeholder="Enter Title"
                          inputClass="filterInput searchInput"
                          onChange={(e)=>setDateTo(e.target.value)}
                          min="1900-01-01" 
                          max="2099-12-31"
                        />
                      </div>
                    </>
                  )
                }
                
                </div> */}
               
             <div className="d-flex">
             {/* <div className="mt-3 ms-2">

                <button className="btn  text-white border-0" onClick={() => setHideFilters(!hideFilters)}><FontAwesomeIcon icon={faCalendarDays} size="xl" /></button>
             </div> */}
             <CustomFilters
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
              />
              
             </div>
            </div>
          </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomTable headers={soundHeader}>
                                <tbody>
                                    {data &&
                                        data.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <button type="button" className="btn lightColor outline-0 border-0">{index + 1}</button>
                                                </td>
                                                <td className="text-capitalize">
                                                    {item.email}
                                                </td>
                                                <td>
                                                    {item.role}
                                                </td>
                                                <td>
                                                    {item.details}
                                                </td>
                                               
                                                <td>
                                                    {item?.action}
                                                </td>
                                                <td>{moment(item?.timestamp).format('MM-DD-YYYY')}<br />{moment(item?.timestamp).format('HH:mm:ss')}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </CustomTable>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomPagination
                                enteries={enteries}
                                totalCount={totalCount}
                                setCurrentPage={setOffset}
                                currentPage={offset}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>

            {loader && <CustomLoader />}

            

        </>
    );
};
export default LogsManagement;
