import { useParams } from "react-router";
import CategoryComponent from "../CategoryComponent";

const EditSoundCategory = () => {
  const { id } = useParams();
  return (
    <>
      <CategoryComponent
        name={"Sound"}
        baseAPI={`/api/sounds/soundscategories/${id}`}
      />
    </>
  );
};
export default EditSoundCategory;
