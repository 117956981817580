import { useParams, } from "react-router";

import CategoryComponent from "../CategoryComponent";

const EditArticleCategory = () => {
  const { id } = useParams();



  return (
    <>
      <CategoryComponent
        name={"Article "}
        baseAPI={`/api/articles/articlecategories/${id}`}
      />
    </>
  );
};
export default EditArticleCategory;
