import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomLoader from "../../Components/CustomLoader";

import "./style.css";
import BASEURL from "../../Config/global";
import { checkAudioFormat } from "../../Util/helpers";
import { patchRequest } from "../../Util/requests";

const EditSound = () => {
  const navigate = useNavigate();
  const mobile_audio = useRef(null)
  const { id } = useParams();
  const [data, setData] = useState({});
  const [soundOptions, setSoundOptions] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [featuredState, setFeaturedState] = useState(false);

  const [soundAndroid, setSoundAndroid] = useState(null);
  const [soundErrorAndroid, setSoundErrorAndroid] = useState(false);

  const [soundIphone1, setSoundIphone1] = useState(null);
  const [soundErrorIphone1, setSoundErrorIphone1] = useState(false);

  const [soundIphone2, setSoundIphone2] = useState(null);
  const [soundErrorIphone2, setSoundErrorIphone2] = useState(false);

  const [mobileSound, setMobileSound] = useState(null);
  const [mobileSoundError, setMobileSoundError] = useState(false);

  const [soundImage, setSoundImage] = useState(null);
  const [soundThumbnail, setSoundThumbnail] = useState(null);

  const [error, setError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);
  const [subCategories, setSubCategories] = useState([])
  const [soundSubCategory, setSoundSubCategory] = useState()
  const [childContent, setChildContent] = useState(false);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/sounds/${id}`);
        console.log(response.data.data[0])
        setData(response.data.data[0]);
        if (response.data.data[0]) {
          if (response.data.data[0].subcategory_obj.child_subcategory_id) {
            setSoundSubCategory(response.data.data[0].subcategory_obj.child_subcategory_id)
          } else {
            setSoundSubCategory("undefined")
          }
        }
        setFeaturedState(response.data.data[0].featured);
        setChildContent(response.data.data[0].child_content);

      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchSound() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/sounds/soundscategories`
        );
        setSoundOptions(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchSound();
  }, []);
  useEffect(()=>{
    if(data["soundcategory"]){
      getSubCategoriesOfCurrentSoundCategory(data["soundcategory"])
    }
  },[data])
  const getSubCategoriesOfCurrentSoundCategory = async (soundcategory) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    fetch(`${BASEURL}/api/sounds/soundscategories/${soundcategory}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          const { category_data } = result?.data[0]
          setSubCategories(category_data)
        }
      })
      .catch((error) => console.error(error));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setError({ error: false, message: "" })
  };
  const handleSubCategoryChange = (event) => {
    const { name, value } = event.target;
    setSoundSubCategory(value);
  };
  const handleChangeCategory = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });

    if (name === 'soundcategory') {
      setSoundSubCategory("undefined");
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file === null || file === "undefined") {
      setSoundImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundImage(reader.result);
      };
    }
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    setSelectedThumbnail(file);

    if (file === null || file === "undefined") {
      setSoundThumbnail(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundThumbnail(reader.result);
      };
    }
  };

  const updateSound = async () => {
    const formDataToSend = new FormData();

    if (data.title) {
      formDataToSend.append("title", data.title);
    }

    if (data.premium) {
      formDataToSend.append("premium", data.premium);
    }

    if (featuredState) {
      formDataToSend.append("featured", featuredState);
    }
    if (soundSubCategory !== "undefined" && soundSubCategory) {
      formDataToSend.append("soundcategory", soundSubCategory);
    }else{
      formDataToSend.append("soundcategory", data.soundcategory);
    }

    if (selectedThumbnail) {
      formDataToSend.append("thumbnail", selectedThumbnail);
    }

    if (soundAndroid) {
      formDataToSend.append("audio", soundAndroid);
    }

    if (soundIphone1) {
      formDataToSend.append("ios_sound", soundIphone1);
    }

    if (soundIphone2) {
      formDataToSend.append("ios_patch", soundIphone2);
    }

    if (mobileSound) {
      formDataToSend.append("audio_mobile", mobileSound);
    }

    if (selectedImage) {
      formDataToSend.append("image", selectedImage);
    }

    if (mobile_audio?.current?.duration) {
      formDataToSend.append("duration", mobile_audio?.current?.duration);
    }
    formDataToSend.append("child_content", childContent);

    setLoader(true);
    try {
      const response = await patchRequest(`/api/sounds/${id}`, formDataToSend, null)
      
      if (response.data.error === false) {
        successModal.fire({
          text: "Sound Updated Successfully",
          confirmButtonText: "Continue",
        });
        setError({ error: false, text: "" });
        setLoader(false);
        navigate("/sound-management");
      } else {
        setError({ error: true, text: response.data.message });
        setLoader(false);
      }
    } catch (error) {
      setError({
        error: true,
        text: "An error occurred. Please try again later.",
      });
    }
  };

  const handleSoundChangeAndroid = (event) => {
    const audioResult = checkAudioFormat(event);
    setSoundAndroid(audioResult.selectedFile);
    setSoundErrorAndroid(audioResult.error);
  };

  const handleSoundChangeIphone1 = (event) => {
    const audioResult = checkAudioFormat(event);
    setSoundIphone1(audioResult.selectedFile);
    setSoundErrorIphone1(audioResult.error);
  };

  const handleSoundChangeIphone2 = (event) => {
    const audioResult = checkAudioFormat(event);
    setSoundIphone2(audioResult.selectedFile);
    setSoundErrorIphone2(audioResult.error);
  };

  const handleMobileSoundChange = (event) => {
    const audioResult = checkAudioFormat(event);
    setMobileSound(audioResult.selectedFile);
    setMobileSoundError(audioResult.error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!data?.title || data?.title === "") {
      setError({ error: true, text: "Title is required. Please enter title to update." });
      return;
    }
    questionModal
      .fire({
        title: "Do you want to Update this Sound?",
        confirmButtonText: "Update",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateSound();
        }
      });
  };

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Edit Sound
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            {data && (
              <div className="col-12">
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-lg-6 mb-2">
                      <CustomInput
                        label="Title"
                        labelClass="mainLabel"
                        type="text"
                        name="title"
                        value={data.title || ""}
                        placeholder="Enter Title"
                        inputClass="mainInput"
                        // required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Type</p>
                      <label className="secondaryText me-3">
                        <input
                          type="radio"
                          name="premium"
                          value="false"
                          className="me-1"
                          checked={data.premium == "true" ? false : true}
                          onChange={handleChange}
                        />
                        Free
                      </label>
                      <label className="secondaryText">
                        <input
                          type="radio"
                          name="premium"
                          value="true"
                          checked={data.premium == "true" ? true : false}
                          className="me-1"
                          onChange={handleChange}
                        />
                        Premium
                      </label>
                    </div>
                    {/* sound OGG format */}
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Sound (Ogg file)*</p>
                      <div className="d-flex flex-column gap-1">
                        {data?.audio ? (
                          <audio className="audioPlayer audio-bar" controls>
                            <source src={`${data?.audio}`} />
                            Your browser does not support the audio element.
                          </audio>
                        ) : <p className="audioInputName oneLine">You have no sound uploaded yet!</p>}
                        <label>
                          <div className="audioInput">
                            <span>{data.audio ? 'Reupload' : 'Upload'} Audio File</span>
                          </div>
                          {soundAndroid && (
                            <p className="audioInputName oneLine">{soundAndroid.name}</p>
                          )}
                          {soundErrorAndroid && (
                            <p className="audioInputName">
                              Please select correct file format
                            </p>
                          )}
                          <input
                            type="file"
                            name="sound"
                            accept="audio/*"
                            className="d-none"
                            onChange={handleSoundChangeAndroid}
                          />
                        </label>
                      </div>
                    </div>
                    {/* iphone MP3 format */}
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Iphone Sound <span style={{ fontSize: "10px" }}>(Mp3 file)</span>*</p>
                      <div className="d-flex flex-column gap-1">
                        {data?.ios_sound ? (
                          <audio className="audioPlayer audio-bar" controls>
                            <source src={`${data?.ios_sound}`} />
                            Your browser does not support the audio element.
                          </audio>
                        ) : <p className="audioInputName oneLine">You have no sound uploaded yet!</p>}
                        <label>
                          <div className="audioInput">
                            <span>{data?.ios_sound ? 'Reupload' : 'Upload'} Audio File</span>
                          </div>
                          {soundIphone1 && (
                            <p className="audioInputName oneLine">{soundIphone1.name}</p>
                          )}
                          {soundErrorIphone1 && (
                            <p className="audioInputName">
                              Please select correct file format
                            </p>
                          )}
                          <input
                            type="file"
                            name="sound"
                            accept="audio/*"
                            className="d-none"
                            onChange={handleSoundChangeIphone1}
                          />
                        </label>
                      </div>
                    </div>
                    {/* iphone gosht MP3 format */}
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Iphone Sound Ghost<span style={{ fontSize: "10px" }}>(Mp3 Patch file)</span>*</p>
                      <div className="d-flex flex-column gap-1">
                        {data?.ios_patch ? (
                          <audio className="audioPlayer audio-bar" controls>
                            <source src={`${data?.ios_patch}`} />
                            Your browser does not support the audio element.
                          </audio>
                        ) : <p className="audioInputName oneLine">You have no sound uploaded yet!</p>}
                        <label>
                          <div className="audioInput">
                            <span>{data?.ios_patch ? 'Reupload' : 'Upload'} Audio File</span>
                          </div>
                          {soundIphone2 && (
                            <p className="audioInputName oneLine">{soundIphone2.name}</p>
                          )}
                          {soundErrorIphone2 && (
                            <p className="audioInputName">
                              Please select correct file format
                            </p>
                          )}
                          <input
                            type="file"
                            name="sound"
                            accept="audio/*"
                            className="d-none"
                            onChange={handleSoundChangeIphone2}
                          />
                        </label>
                      </div>
                    </div>
                    {/* Mobile Native MP3 format */}
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Mobile Native<span style={{ fontSize: "10px" }}>(Mp3 file)</span>*</p>
                      <div className="d-flex flex-column gap-1">
                        {data.audio_mobile ? (
                          <audio className="audioPlayer audio-bar" controls ref={mobile_audio}>
                            <source src={`${data?.audio_mobile}`} />
                            Your browser does not support the audio element.
                          </audio>
                        ) : <p className="audioInputName oneLine">You have no sound uploaded yet!</p>}
                        <label>
                          <div className="audioInput">
                            <span>{data?.audio_mobile ? 'Reupload' : 'Upload'} Audio File</span>
                          </div>
                          {mobileSound && (
                            <p className="audioInputName oneLine">{mobileSound.name}</p>
                          )}
                          {mobileSoundError && (
                            <p className="audioInputName">
                              Please select correct file format
                            </p>
                          )}
                          <input
                            type="file"
                            name="sound"
                            accept="audio/*"
                            className="d-none"
                            onChange={handleMobileSoundChange}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Select Category*</p>
                      {soundOptions && (
                        <select
                          name="soundcategory"
                          id="category"
                          className="mainInput w-auto"
                          value={data.soundcategory}
                          onChange={handleChangeCategory}
                        >
                          {soundOptions.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      )}
                      </div>
                    <div className="col-lg-6 mb-2 mt-2">
                      <p className="mainLabel">Select SubCategory*</p>
                      <select
                        name="soundSubCategory"
                        id="category"
                        className="mainInput w-auto"
                        value={soundSubCategory}
                        onChange={handleSubCategoryChange}
                      >
                        <option value="undefined">No item selected</option>
                        {subCategories?.length > 0 && subCategories.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6 mb-2 mt-2">
                      <input
                        type="checkbox"
                        name="featured"
                        id="featured"
                        checked={featuredState ? true : false}
                        onChange={() => {
                          setFeaturedState(!featuredState);
                        }}
                      />
                      <label htmlFor="featured" className="mainLabel ms-1">
                        Featured
                      </label>
                    </div>
                    <div className="col-lg-6 mt-2">
                    <input
                      type="checkbox"
                      name="featured"
                      id="featured"
                      onChange={() => {
                        setChildContent(!childContent);
                      }}
                      value={childContent}
                      checked={childContent ? true : false}
                    />
                    <label htmlFor="featured" className="mainLabel ms-1">
                      underage restriction
                    </label>
                  </div>
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Thumbnail*</p>
                      <label>
                        <div className="thumbnailInput">
                          {soundThumbnail ? (
                            <img src={soundThumbnail} alt="Thumbnail" />
                          ) : (
                            <img
                              src={`${data.thumbnail}`}
                              alt="Thumbnail"
                            />
                          )}
                        </div>
                        <input
                          type="file"
                          id="image"
                          accept="image/*"
                          className="d-none"
                          // required
                          onChange={handleThumbnailChange}
                        />
                      </label>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <p className="mainLabel">Image*</p>
                      <label>
                        <div className="imageInput">
                          {soundImage ? (
                            <img src={soundImage} alt="Main" />
                          ) : (
                            <img src={`${data.image}`} alt="Main" />
                          )}
                        </div>
                        <input
                          type="file"
                          id="image"
                          accept="image/*"
                          className="d-none"
                          // required
                          onChange={handleImageChange}
                        />
                      </label>
                    </div>
                  </div>
                  {error.error == true && (
                    <div className="row mb-3">
                      <div className="col-12">
                        <p className="secondaryText">{error.text}</p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <CustomButton
                        type="submit"
                        variant="primaryButton"
                        text="Update"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default EditSound;
