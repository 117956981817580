import CategoryComponent from "../CategoryComponent";
const AddStoryCategory = () => {
  return (
    <>
      <CategoryComponent
        name={"Story"}
        baseAPI={`/api/stories/storiescategories`}
      />
    </>
  );
};
export default AddStoryCategory;
