import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { setAccessToken } from "../../Util/token";

import { AuthLayout } from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";

import "./style.css";
import BASEURL from "../../Config/global";
import { Eye, EyeSlash } from "../../Assets/svg";
import PasswordInput from "../../Components/PasswordInput";

const AdminLogin = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [typePass, setTypePass] = useState(true);
  const [loading, setLoading] = useState(false);


  const togglePassType = () => {
    setTypePass(!typePass);
  };
  const handleClick = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("username", formData.username);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("is_admin_portal", true);
    // formDataToSend.append("admin", "true");
    try {
      setLoading(true);
      const response = await axios.post(`${BASEURL}/api/user/login/`, formDataToSend);
      if (response.data.error === false) {
        const token = response.data.data[0].token;
        const permissionList = response.data.data[0].permissions
        const firstModuleWithAccess = permissionList.find(module => module.can_access === true);
        const userInfo = response.data.data[0];

        setAccessToken(token, permissionList, userInfo)
        setTimeout(() => {
          if (firstModuleWithAccess) {
            const routeName = firstModuleWithAccess.module_name.split(' ').join("-").toLowerCase()
            navigate('/' + routeName);
          } else {
            navigate('/unautherized-acess')
          }
          setLoading(false);
        }, 1000)
        
        setLoginError({ error: false, text: "" });
      } else {
        setLoginError({ error: true, text: response.data.message });
        setLoading(false);
      }
    } catch (error) {
      setLoginError({
        error: true,
        text: "An error occurred. Please try again later.",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <AuthLayout>
        <form style={{minWidth:'450px'}}>
          <CustomInput
            label="Email"
            required
            id="email"
            type="text"
            labelClass="mainLabel"
            inputClass="mainInput"
            onChange={(event) => {
              setFormData({
                ...formData,
                username: event.target.value,
              });
            }}
          />
          <PasswordInput
            label="Password"
            required
            id="pass"
            type="password"
            labelClass="mainLabel"
            inputClass="mainInput"
            onChange={(event) => {
              setFormData({
                ...formData,
                password: event.target.value,
              });
            }}
            />
          {loginError.error === true && (
            <div className="col-12">
              <p className="secondaryText">{loginError.text}</p>
            </div>
          )}
          <div className="text-end mt-3">
            <Link to={"/recover-password"} className="authFormLinks">
              Forgot Password?
            </Link>
          </div>
          <div className="row mt-4 text-center">
            <div className="col-12">
            {
                loading ? (
                  <button class="primaryButton customButton" onClick={(e) => { e.preventDefault() }}>
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Login"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
};

export default AdminLogin;
