import CategoryComponent from "../CategoryComponent";

const AddFeaturedMixCategory = () => {


  return (
    <>
      <CategoryComponent
        name={"Featured Mixes"}
        baseAPI={`/api/sounds/mixFeatureCategories`}
      />
    
    </>
  );
};
export default AddFeaturedMixCategory;
