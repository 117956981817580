import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import './style.css'
import BASEURL from '../../Config/global'
import axios from 'axios'
import CustomInput from '../../Components/CustomInput'
import { unstable_usePrompt as usePrompt } from 'react-router-dom'
import { errorModal, questionModal, successModal } from '../../Components/CustomModal'
import { PermissionsContext } from '../../Layout/PermissionContext'
import { disabledButtonByPermission } from '../../Components/Permissions'
import { patchRequest } from '../../Util/requests'
import { Accordion } from 'react-bootstrap'
const PromptsManagement = () => {
    const { permissions } = useContext(PermissionsContext)

    const [formData, setFormData] = useState({
        "My Mix Messages": {
            "delete_mix": "Delete mix message",
            "delete_mix_nofitication": "Delete mix notification",
            "mix_saved_notification": "Mix saved notification",
        },
        "Underage restrictions Messages": {
            "no_available_resourse": "Restricted url message",
            "age_restriction_notification": "Share Mix contains article notification",
            "emptymix_age_restriction_notification": "Empty mix notification",
        },
        "General Messages": {
            "logout_message": "Logout user message",
            "logout_message_notification": "User logout notification",
            "profile_edit_success": "Edit Profile success message",
            "subscription_cancel_message": "Subscription cancel message",
            "subscription_cancel_notification": "Subscription cancel notification",
            "link_copied_notification": "Link copied notification",
            "email_invite_message": "Email invite message",
            "invalid_card_details": "Invalid card details",

        },
        "Payment Messages": {
            "payment_success_message": "Payment saved/success message (for subscriptions)",
            "subscription_updated_successfully": "Subscription updated message (for subscriptions)",
            "payment_decline_message": "Payment decline message",
            "payment_paid_successfully_message": "Payment paid successfully message (for Promo)",
            "payment_saved_successfully_message": "Payment saved successfully message (for Promo)",
        },
        "Household Members Messages": {
            "household_remove_message": "Remove household user message",
            "household_remove_notification": "Remove household user notification",
        },
        "Form Messages": {
            // "signup_success_notification": "Signup success notification",
            "user_exist_not_message": "User does not exist message",
            "email_verfication_message": "Email verification message",
            "email_required_message": "Email required message",
            "password_required_message": "Password required message",
            "confirm_password_required_message": "Confirmed password required message",
            "password_length_message": "Password length message",
            "password_not_match_message": "Password not match message",
            "forget_otp_sent_msg": "Otp wrong message",
            "reset_password_notification": "Reset password notification",
            "firstname_validate": "First name validate",
            "lastname_validate": "Last name validate",
            "country_validate": "Country name validate",
            "wrong_country_for_promo": "Wrong country for promo",
            "birth_day_validate": "Birthday validate",
            "invalid_creds_message": "Invalid creds message",
            "validate_address": "Invalid address message"

        },
        "Share&Earn Messages": {
            "financial_profile_agreement_message": "Agreement message",
        },
        "Mobile Messages": {
            "login_to_web_app": "Web app login message",
        },
    });
    const [selectedPrompt, setSelectedPrompt] = useState({});
    const [updatedValues, setUpdatedValues] = useState({})

    useEffect(() => {
        fetchData()
    }, [])

    const modulesWisePermission = (condition) => {
        if (!permissions) {
            return 'd-none'
        }
        return disabledButtonByPermission(permissions, condition)
    }

    async function fetchData() {
        try {
            let url = `${BASEURL}/api/misc/keys/`;
            const response = await axios.get(url);
            if (!response.data.error) {
                let tranformReponse = {}
                const messages = response.data.data
                for (let index = 0; index < messages.length; index++) {
                    const element = messages[index];
                    tranformReponse = { ...tranformReponse, ...element }
                }
                setSelectedPrompt(tranformReponse)
            } else {
                console.log(response.data.message);
            }

        } catch (error) {
            console.error(error);
        }
    }


    const handleUpdateMessages = async () => {
        if (Object.keys(updatedValues).length === 0) {
            errorModal.fire({
                text: 'Please make some new changes to update',
                confirmButtonText: "Continue",
            });
            return
        }
        questionModal
            .fire({
                title: "Do you want to update the prompts?",
                confirmButtonText: "Update",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await patchRequest(`/api/misc/keys/`, updatedValues)
                    if (!response.data.error) {
                        successModal.fire({
                            text: 'Prompts updated Successfully',
                            confirmButtonText: "Continue",
                        });
                        setUpdatedValues({})
                    }
                }
            })
    }
    return (
        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">{"Prompts"} </h2>
                </div>

            </div>
            <div className='row'>
                {
                    Object.keys(selectedPrompt).length > 0 ? Object.keys(formData).map((key, index) => {
                        return (
                        <div className='col-12 my-1' key={key}>
                           
                            <Accordion
                                className='swap-modal-for-permission '
                                defaultActiveKey={0}
                                
                            >
                                    <Accordion.Item
                                        className='customAccordion'
                                        key={`Accordion-${index}`}
                                        eventKey={index}
                                    >

                                        <Accordion.Header
                                            className='stop-header-from-closing'
                                        >
                                            <h5>{key}</h5>
                                        </Accordion.Header>

                                        <Accordion.Body className="accordion-body">
                                            <div className='row my-3'>
                                                {
                                                    Object.keys(formData[key]).length > 0 ? (
                                                        Object.keys(formData[key]).map(subKey => (
                                                            <div className="col-lg-4 pe-4 my-4" key={subKey}>
                                                                <CustomInput
                                                                    label={formData[key][subKey]}
                                                                    labelClass="mainLabel"
                                                                    required
                                                                    type="text"
                                                                    name={formData[key][subKey]}
                                                                    value={selectedPrompt[subKey]}
                                                                    // placeholder="Enter tex"
                                                                    inputClass="mainInput"
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        setSelectedPrompt({ ...selectedPrompt, [subKey]: newValue })
                                                                        setUpdatedValues({ ...updatedValues, [subKey]: newValue })
                                                                    }}
                                                                />
                                                            </div>
                                                        ))
                                                    ) : null

                                                }
                                            </div>
                                            <div className='my-3 d-flex justify-content-between align-items-center'>

                                                <div className="text-end mb-2">
                                                    <button
                                                        onClick={handleUpdateMessages}
                                                        className={`customButton primaryButton ${modulesWisePermission('can_edit')}`}
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                            </Accordion>
                          

                        </div>)
                    }) : (
                        <div className="center-align">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    )
                }
            </div>

        </DashboardLayout>
    )
}

export default PromptsManagement

