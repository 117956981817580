import { useParams, } from "react-router";
import CategoryComponent from "../CategoryComponent";

const EditFeaturedMixCategory = () => {
    const { id } = useParams();
    return (
        <>
            <CategoryComponent
                name={"Featured Mixes"}
                baseAPI={`/api/sounds/mixFeatureCategories/${id}`}
            />
        </>
    );
};
export default EditFeaturedMixCategory;
