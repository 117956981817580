const TOKEN_NAME = "admin_token";
const PERMISSION_LIST = "permissionList";
const USER_INFO = "user_info";



// export const getAuthHeader = () => {
//   return {
//     Authorization: `Bearer ${localStorage.getItem(TOKEN_NAME)}`,
//   };
// };

export const getAccessToken = () => localStorage.getItem(TOKEN_NAME);

export const removeAccessToken = () => {
  localStorage.removeItem(TOKEN_NAME)
  localStorage.removeItem(PERMISSION_LIST)
  localStorage.removeItem(USER_INFO)
};


export const setAccessToken = (token, permissionList, userInfo) => {
  localStorage.setItem(TOKEN_NAME, token);
  localStorage.setItem(PERMISSION_LIST, JSON.stringify(permissionList));
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo))
};
