import { useState, useEffect, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

import { DashboardLayout } from "../../../Layout/DashboardLayout";

import CustomTable from "../../../Components/CustomTable";
import BASEURL from "../../../Config/global";
import CustomPagination from "../../../Components/CustomPagination";
import CustomLoader from "../../../Components/CustomLoader";
import BackButton from '../../../Components/BackButton';
import CustomFilters from "../../../Components/CustomFilters";


const PromoList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [enteries, setEnteries] = useState(10);
    const [offset, setOffset] = useState(0);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [filterSearchValue, setFilterSearchValue] = useState("");
    const [sort, setSort] = useState("all");
    const [totalCount, setTotalCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);


    useEffect(() => {
        if (currentPage != undefined) {
            setOffset(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        document.title = "Relax Scofa | Promo Management";
    }, []);

    useEffect(() => {
        fetchData();
    }, [enteries, dateTo, filterSearchValue, offset]);

    async function fetchData() {
        try {
            const response = await axios.get(
                `${BASEURL}/api/user/coupon?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
            );
            if (response.data.error != true) {
                // console.log(response.data.data);
                setData(response.data.data);
                setTotalCount(response.data.count);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const couponsHeader = [
        {
            key: "title",
            title: "Promo Title",
        },
        {
            key: "code",
            title: "Promo Code",
        },
        {
            key: "action",
            title: "Promo Category",
        },
    ];

    const handleChangeEntries = (no) => {
        setOffset(0)
        setEnteries(no)
    }

    const handleClick = (data) => {
        const obj = {
            "id": data?.id,
            "title": data?.title,
        }
        navigate(`/promo-category/${obj?.id}`, { state: obj });
        // console.log(data)
    }


    return (
        <>
            <DashboardLayout>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-lg-8 mb-2">
                            <h2 className="mainTitle">
                                <BackButton />
                                Promos Category
                            </h2>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomFilters
                                enteries={enteries}
                                totalCount={totalCount}
                                entriesFilter={true}
                                setEnteries={handleChangeEntries}
                                filterSearch={true}
                                filterSearchValue={filterSearchValue}
                                setFilterSearchValue={setFilterSearchValue}
                                // dateFilter={true}
                                setDateFrom={setDateFrom}
                                setDateTo={setDateTo}
                            // isSearchDropdown={couponsHeader}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomTable headers={couponsHeader}>
                                <tbody>
                                    {data &&
                                        data.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link
                                                        className="lightColor text-decoration-none"
                                                        // to={`/promo-management/promo-list/categories/${item?.id}`}
                                                        onClick={() => handleClick(item)}
                                                    >
                                                        {item.title}
                                                    </Link>
                                                </td>
                                                <td>{item?.code}</td>
                                                <td>
                                                    <button onClick={() => handleClick(item)} className="customButton primaryButton me-3">
                                                        Show Categories
                                                    </button>
                                                    {/* <Link
                                                        to={`/promo-management/promo-list/categories/${item?.id}`}
                                                        className={`tableAction`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            className="tableActionIcon"
                                                        />
                                                        Show Categories
                                                    </Link> */}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </CustomTable>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomPagination
                                enteries={enteries}
                                totalCount={totalCount}
                                setCurrentPage={setOffset}
                                currentPage={offset}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {loader && <CustomLoader />}
        </>
    )
}

export default PromoList