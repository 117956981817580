import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



import { AuthLayout } from '../../Layout/AuthLayout';
import PasswordInput from "../../Components/PasswordInput"

import CustomButton from '../../Components/CustomButton';
// import CustomModal from '../../Components/CustomModal';

import "./style.css";
import { successModal } from '../../Components/CustomModal';
import BASEURL from '../../Config/global';
import axios from 'axios';

const ResetPassword = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({})

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: false, text: "" });
  useEffect(() => {
    document.title = 'Relax Scofa | Password Recovery';
  }, [])

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorMsg({ error: false, text: "" });
  }

  const handleClick = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    const userEmail = localStorage.getItem("admin_email");
    if (!formData.password || formData.password === "") {
      setErrorMsg({ error: true, text: "Please enter password" });
      return
    }
    if (!formData.confirm_password || formData.confirm_password === "") {
      setErrorMsg({ error: true, text: "Please enter confirm password" });
      return
    }
    if (formData.confirm_password !== formData?.password) {
      setErrorMsg({ error: true, text: "Please enter same password" });
      return
    }
    if ((formData.password.length < 6) || (formData.confirm_password.length < 6)) {
      setErrorMsg({
        error: true,
        text: "Password must contain 6 characters",
      });
    }
    else if (formData.password !== formData.confirm_password) {
      setErrorMsg({
        error: true,
        text: "Password doesn't match",
      });
    }
    formDataToSend.append("email", userEmail?.trim());
    formDataToSend.append("password", formData.password);

    setLoading(true)
    try {

      if (formData?.password?.length > 0) {
        const response = await axios.post(`${BASEURL}/api/user/reset_password/`, formDataToSend);
        if (!response.data.error) {
          setLoading(false)
          setTimeout(() => {
            successModal.fire({
              text: "Password Successfully Updated",
              confirmButtonText: "Continue",
            });
            navigate('/login')
          }, 1000);
        } else {
          setLoading(false)
        }
      }

    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <AuthLayout>
        <form>
          <PasswordInput
            label='New Password'
            required
            id='pass'
            type='password'
            name={"password"}
            placeholder='Enter New Password'
            labelClass='mainLabel'
            inputClass='mainInput'
            value={formData.password}
            onChange={onChange}
          />

          <PasswordInput
            label='Confirm Password'
            required id='cPass'
            type='password'
            name="confirm_password"
            placeholder='Confirm Password'
            labelClass='mainLabel'
            inputClass='mainInput'
            value={formData.confirm_password}
            onChange={onChange}
          />
          
          
          {errorMsg.error === true && (
            <div className="col-12">
              <p className="secondaryText">{errorMsg.text}</p>
            </div>
          )}
          <div className="row mt-4 ">
            <div className="col-12 text-center">
              {
                loading ? (
                  <button class="primaryButton customButton" onClick={(e) => { e.preventDefault() }}>
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Update"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>

        </form>
      </AuthLayout>

      {/* <CustomModal show={showModal} close={() => { setShowModal(false) }} success heading='Password Successfully Updated' /> */}
    </>
  )
}



export default ResetPassword





