/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 11/11/2023 - 23:18:14
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 11/11/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";

import { placeholderImage } from "../../Assets/images";

import "./style.css";
import BASEURL from "../../Config/global";
import moment from "moment";
import CountrySelector from "../../Components/CountrySelect";
import { successModal } from "../../Components/CustomModal";
import { patchRequest } from "../../Util/requests";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [updateFormData, setUpdateFormData] = useState({});
  const [error, setError] = useState({ error: false, text: "" });
  const [loading, setLoading] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/profile/${id}`
      );
      if (!response.data.error) {
        console.log(response?.data);
        setFormData(response?.data?.data[0]);
        setProfileImage(response?.data?.data[0].user_image);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => {
    setError({ error: false, text: "" });
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setUpdateFormData({ ...updateFormData, [name]: value });
  };

  const handleChangeCountry = (val) => {
    setError({ error: false, text: "" });
    setFormData({ ...formData, country: val });
    setUpdateFormData({ ...updateFormData, country: val });
  };

  const handler = (event) => {
    const { name, value } = event.target;
    if (event.key === 'Backspace') {
      if (value.length == 3) {
        setFormData({ ...formData, [name]: value.slice(0, 2) });
        setUpdateFormData({ ...updateFormData, [name]: value.slice(0, 2) });
      } else if (value.length == 6) {
        setFormData({ ...formData, [name]: value.slice(0, 5) });
        setUpdateFormData({ ...updateFormData, [name]: value.slice(0, 5) });
      } else if (value.length <= 10 || value.length == '') {
        setFormData({ ...formData, [name]: value });
        setUpdateFormData({ ...updateFormData, [name]: value });
      }
    }
  };

  const changeMe = (e) => {
    const { name, value } = e.target;

    const sanitizedInput = value.replace(/[^0-9/]/g, '');
    const truncatedInput = sanitizedInput.slice(0, 10);
    const matches = truncatedInput.match(/^(\d{0,2})\/?(\d{0,2})\/?(\d{0,})$/);
    if (matches) {
      const [, month, day, year] = matches;
      const isValidMonth = month === '' || (parseInt(month, 10) <= 12);
      const isValidDay = day === '' || (parseInt(day, 10) <= 31);
      const isValidYear = year === '' || (!isNaN(year));
      if (isValidMonth && isValidDay && isValidYear) {
        const formattedInput = [month, day, year].filter(Boolean).join('/');
        // setFormData({ ...formData, 'date_of_birth': formattedInput });
        setFormData({ ...formData, 'date_of_birth': formattedInput });

        setUpdateFormData({ ...updateFormData, 'date_of_birth': formattedInput });

      } else {
        console.log("Invalid date");
      }
    } else {
      console.log("Invalid date format");
    }
  };
  const validDateDateFormat = () => {
    if (formData?.date_of_birth?.length > 0) {
      if (formData.date_of_birth.length < 10) {
        return false
      }
    }
    return true
  };
  const profileUpdated = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validDateDateFormat()) {
      setLoading(false);
      setError({
        error: true,
        text: "Invalid date format. Please enter a valid date in mm/dd/yyyy format.",
      });
      return
    }
    if (!formData.first_name || formData.first_name === "") {
      setLoading(false);
      setError({
        error: true,
        text: "First name should be not empty. Please Fill first name",
      });
      return
    }
    
    if (!formData.last_name || formData.last_name === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Last name should be not empty. Please Fill last name",
      });
      return
    }
    // if (!formData.zip || formData.zip === "") {
    //   setLoading(false);
    //   setError({
    //     error: true,
    //     text: "Zip should be not empty. Please Fill Zip",
    //   });
    //   return
    // }
    if (!formData.email || formData.email === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Email should be not empty. Please Fill email",
      });
      return
    }
    // if (!formData.phone_number || formData.phone_number === "") {
    //   setLoading(false);
    //   setError({
    //     error: true,
    //     text: "Phone should be not empty. Please Fill Phone",
    //   });
    //   return
    // }
    // const user = JSON.parse(localStorage.getItem("user"));
    let selectedCountry;
    if (!formData.country || formData.country === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Country name should be not empty. Please Fill country",
      });
      return
    } else {
      let checkCountryForUSA = formData.country?.toLowerCase()
      if (checkCountryForUSA === "usa" || checkCountryForUSA === "us" || checkCountryForUSA === "u.s.a" || checkCountryForUSA === "america" || checkCountryForUSA === "united states") {
        selectedCountry = "United States of America"
      } else {
        selectedCountry = formData.country;
      }
    }
    const formDataToSend = new FormData();
    let updateForWithData = {...updateFormData, country:selectedCountry}
    for (const key in updateForWithData) {
      formDataToSend.append(key, updateForWithData[key]);
    }
    if (profileImageFile) {
      formDataToSend.append("user_image", profileImageFile);
    }
    if (Object.keys(updateFormData).length !== 0 || profileImageFile) {
      try {
        const response = await patchRequest(`/api/user/profile/${id}`, formDataToSend, null)
        if (!response.data.error) {
          setLoading(false);
          setSuccesModal(true);
          successModal.fire({
            text: 'User edit successfull',
            confirmButtonText: "Continue",
          });
          navigate(-1)
          navigate("/user-management");
          // setTimeout(() => {
          //   setSuccesModal(false);
          // }, 2500);
        } else {
          console.log("error 123")
          setLoading(false);
          setError({ error: true, text: response?.data?.data });

        }
      } catch (error) {
        setLoading(false);

        console.log(error)
        setError({ error: true, text: 'something went wrong' });

      }
    } else {
      setLoading(false);
      setError({ error: true, text: "No field is updated!" });
    }

  }


  return (
    <DashboardLayout>
      <div className="row mb-3">
        <div className="col-12 mb-2">
          <h2 className="mainTitle">
            <BackButton />
            Edit User
          </h2>
        </div>
      </div>
      <form>
          <div className="row">
           <div className="col-lg-3">
              <CustomInput
                label="First Name"
                required
                id="fname"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="first_name"
                value={formData.first_name || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-3">
              <CustomInput
                label="Last Name"
                required
                id="lname"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="last_name"
                value={formData.last_name || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6">
              <CustomInput
                label="Email"
                required
                id="email"
                type="email"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}
                autocomplete="new-email"

              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="City"
                id="city"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="city"
                value={formData.city || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="State"
                id="state"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="state"
                value={formData.state || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="Zip"
                id="zip"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="zip"
                value={formData.zip || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
            <CountrySelector
              value={formData.country || ""}
              handleChange={handleChangeCountry}
              inputClass={''}
              name={'Country'}
            />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="Birthdate"
                id="date"
                type="text"
                placeholder="mm/dd/yyyy"
                labelClass="mainLabel"
                inputClass="mainInput place-holder-white"
                name="date_of_birth"
                value={formData?.date_of_birth || ""}
                onChange={changeMe}
                onKeyDown={handler}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="Phone"
                id="phone"
                type="number"
                placeholder=""
                labelClass="mainLabel"
                inputClass="mainInput"
                name="phone_number"
                value={formData?.phone_number || ""}
                onChange={handleChange}
              />
            </div>
           
            {error.error && (
              <div className="col-12">
                <p className="smallText lightColor">{error.text}</p>
              </div>
            )}
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
              {
                loading ? (
                  <button class="primaryButton customButton">
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Update Profile"
                      onClick={profileUpdated}
                    />
                  )
              }
            </div>
          </div>
        </form>
      {/* <CustomToast
          show={successAlert}
          title={"Successful"}
          message={"Signed Up"}
          close={() => {
            setSuccessAlert(false);
          }}
        /> */}
      {/* {showVerifyModal && <VerifyModal coupon={coupon} verifiedCoupon={verifiedCoupon} />} */}
    </DashboardLayout>
  );
};
export default EditUser;
