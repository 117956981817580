import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Link } from 'react-router-dom'
import { Dropdown, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons'
import CustomInput from '../../Components/CustomInput'
import { AddImageComponent } from '../CategoryManagement/ImageComponent'
import './style.css'
import CustomTable from '../../Components/CustomTable'
import BASEURL from '../../Config/global'
import axios from 'axios'
import { questionModal, successModal } from '../../Components/CustomModal'
import { disabledButtonByPermission } from '../../Components/Permissions'
import { PermissionsContext } from '../../Layout/PermissionContext'
import { deleteRequest, patchRequest, postRequest } from '../../Util/requests'
const PlatformManagement = () => {
    const [swapInputError, setInputError] = useState({ error: true, text: '' });
    const [showSwapModal, setShowSwapModal] = useState(false);
    const [formData, setFormData] = useState(false);
    const [iconImage, setIconImage] = useState(null);
    const [editId, setEditId] = useState("");
    const [platform, setPlatformList] = useState([]);
    const { permissions } = useContext(PermissionsContext)


    useEffect(() => {
        fetchData()
    }, [])

    const modulesWisePermission = (condition) => {
        if (!permissions) {
          return 'd-none'
        }
        return disabledButtonByPermission(permissions, condition)
      }

    async function fetchData() {
        try {

            const response = await axios.get(
                `${BASEURL}/api/user/platforms/`
            );
            if (response.data.error !== true) {
                // console.log(response.data.data);
                setPlatformList(response.data.data);
                // setPlatformList(response.data.count);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const isValidUrl = urlString => {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }
    const updateOrAddCategory = async () => {
        const { title, icon, url } = formData;
        if (title === "" || title === undefined || icon === "" || icon === undefined) {
            setInputError({ error: true, text: 'Please fill all fields' });
            return;
        }
        console.log(url)
        if (url && url !== "") {
            if (!isValidUrl(url)) {
                setInputError({ error: true, text: 'Please enter valid url' });
                return;
            }
        }
        
        questionModal
            .fire({
                title: `Do you want to ${editId ? "update" : "add"} this Platform?`,
                confirmButtonText: editId ? "Update" : "Add",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await postData().then((result) => {
                        if (editId) {
                            const findIndex = platform.findIndex(f => f.id === editId)
                            platform[findIndex] = { id: editId, ...formData }
                            setPlatformList(platform)
                        } else {
                            const id = platform.length + 1
                            setPlatformList((prevState) => [{ ...formData, id }, ...prevState])
                        }
                        setEditId(null)
                        setShowSwapModal(false)
                        setIconImage(null)
                        setFormData({})
                        setInputError({error:false, text:''})
                    })

                }
            });

    }

    const postData = async () => {
        try {
            const { title, icon, url } = formData;
            const raw = JSON.stringify({
                "title": title,
                "icon": icon,
                "url": url
            });
            const URL = `/api/user/platforms/${editId ? editId + '/' : ''}`
            if (editId) {
                return await patchRequest(URL, raw);
            } else {
                return await postRequest(URL, raw);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setInputError({error:false, text:''})

    };

    const handleDeleteItemCallBack = async(item) => {
        questionModal
            .fire({
                title: "Do you want to delete this Platform",
                confirmButtonText: "Delete",
            })
            .then(async(result) => {
                if (result.isConfirmed) {
                    
                    await deleteRequest(`/api/user/platforms/${item.id}/`)
                        .then(() => {
                            setPlatformList([...platform.filter((p, i) => p.id !== item.id)]);
                            successModal.fire({
                                text: "Platform deleted Successfully",
                                confirmButtonText: "Continue",
                            });
                        })
                        .catch((error) => console.error(error));
                }
            });
    }

    const handleEditItemCallBack = (item) => {
        setEditId(item.id)
        setShowSwapModal(true)
        setIconImage(item.icon)
        setFormData({ ...item, })
    }

    const handleImageChangeIcon = (event) => {
        const file = event.target.files[0];
        if (file === null || file === "undefined") {
            setIconImage(null);
            setFormData({ ...formData, icon: null })

        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setIconImage(reader.result);
                setFormData({ ...formData, icon: reader.result })
            };
        }
    };

    const handleRemoveImageIcon = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setFormData({ ...formData, icon: null })
        setIconImage(null)
    }

    const couponsHeader = [
        {
            key: "sr",
            title: "SR",
        },
        {
            key: "icon",
            title: "icon",
        },

        {
            key: "title",
            title: "Title",
        },
        {
            key: "url",
            title: "Url",
        },
        {
            key: "actions",
            title: "Actions",
        },
    ];
    return (
        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">Platforms </h2>
                </div>
                <div className="col-lg-4 text-end mb-2">
                    <button
                        onClick={() => {
                            setEditId("")
                            setShowSwapModal(true)
                            setInputError({ error: false, text: '' })
                            setFormData({})
                            setIconImage(null)
                        }}
                        className={`customButton primaryButton ${modulesWisePermission("can_add")}`}
                    >
                        Add Platforms
                    </button>
                </div>
            </div>
            {

                <div className="col-12">
                    <CustomTable headers={couponsHeader}>
                        <tbody>
                            {platform && platform?.length > 0 &&
                                platform.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>

                                        <td style={{ width: '100px' }}>
                                            {
                                                item.icon && <img
                                                    src={item.icon}
                                                    alt="thumbnail"
                                                    className="thumbnail"
                                                />
                                            }

                                        </td>
                                        <td >{item.title}</td>
                                        <td >{item.url}</td>

                                        <td>
                                            <Dropdown className="tableDropdown">
                                                <Dropdown.Toggle
                                                    variant="transparent"
                                                    className="notButton classicToggle"
                                                >
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    align="end"
                                                    className="tableDropdownMenu"
                                                >
                                                    <button
                                                        onClick={() => {
                                                            handleEditItemCallBack(item)
                                                        }}
                                                        className={`tableAction Button ${modulesWisePermission("can_edit")}`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            className="tableActionIcon"
                                                        />
                                                        Edit
                                                    </button>

                                                    <button
                                                        className={`tableAction ${modulesWisePermission("can_delete")}`}
                                                        onClick={() => {
                                                            handleDeleteItemCallBack(item, index);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            className="tableActionIcon"
                                                        />
                                                        Delete
                                                    </button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </CustomTable>
                </div>
            }
            <Modal
                show={showSwapModal}
                centered
                className="swap-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="pb-5 w-100 d-flex flex-column align-items-center">
                        <div className="d-flex justify-content-between w-100  ">
                            <h2 className="mainTitle">{editId !== "" ? "Edit" : "Add"} Platform Details</h2>
                            <div className="text-end ms-auto">
                                <button
                                    className="closeButton notButton ms-auto"
                                    onClick={() => {
                                        setEditId("")
                                        setShowSwapModal(false)
                                        setInputError({ error: false, text: '' })

                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="cross-icon"
                                        color="white"
                                    />
                                </button>
                            </div>
                        </div>
                        <br />

                        <div className="row w-100 mb-3">
                            <div className='col-4'>
                                <AddImageComponent
                                    title={`Icon`}
                                    inputClass="upload-platform col-12 w-100"
                                    soundImage={iconImage}
                                    handleImageChange={handleImageChangeIcon}
                                    handleRemoveImage={handleRemoveImageIcon}
                                />
                            </div>
                            <div className='col-8 mt-auto '>
                                <CustomInput
                                    label="Platform Title"
                                    labelClass="mainLabel"
                                    required
                                    type="text"
                                    name="title"
                                    value={formData?.title || ""}
                                    placeholder="Enter Title"
                                    inputClass="mainInput"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='row w-100'>
                            <div className="col-12">
                                <CustomInput
                                    label="Platform Url"
                                    labelClass="mainLabel"
                                    type="text"
                                    name="url"
                                    value={formData?.url || ""}
                                    placeholder="Enter Platform Url"
                                    inputClass="mainInput"
                                    onChange={handleChange}
                                />

                            </div>
                        </div>


                        {swapInputError?.error && <small className="text-danger mt-3">{swapInputError.text}</small>}
                        <div className=" pt-5 d-flex gap-3 justify-content-center">
                            <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={updateOrAddCategory}>
                                <span className=''>{editId !== "" ? "UPDATE" : "Add"}</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </DashboardLayout>
    )
}

export default PlatformManagement