import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisV, faTimes, } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../Components/CustomTable";
import { Dropdown } from "react-bootstrap";
import CustomPagination from "../../Components/CustomPagination";

const SubCategoryComponent = ({ data, handleDeleteItemCallBack, handleEditItemCallBack }) => {


  const headers = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "subcategories",
      title: "Sub Categories",
    },
    {
      key: "underagerestriction",
      title: "under age restriction",
    },
    {
      key: "nooftracks",
      title: "No of tracks",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];
  console.log("data====>", data)
  return (
    <>
      <CustomTable headers={headers}>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>
                  {index + 1}
                </td>
                <td className="text-capitalize">{item.name}</td>
                <td className="text-capitalize">{item.child_content?"YES":"NO"}</td>
                <td className="text-capitalize">{item.sound_count}</td>
                <td>
                  <Dropdown className="tableDropdown">
                    <Dropdown.Toggle
                      variant="transparent"
                      className="notButton classicToggle"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="end"
                      className="tableDropdownMenu"
                    >


                      <button
                        className={`tableAction`}
                        onClick={() => {
                          handleEditItemCallBack(item)
                        }}
                        to={`/category-management/edit-sound-category/${item.id} `}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="tableActionIcon"
                        />
                        Edit
                      </button>

                      <button
                        type="button"
                        className={`tableAction`}
                        onClick={() => {
                          handleDeleteItemCallBack(
                            item.id,
                            item.name
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="tableActionIcon"
                        />
                        Delete
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTable>
    </>
  );
};
export default SubCategoryComponent;
