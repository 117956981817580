import { useState } from "react";

import axios from "axios";

// import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisV, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';


import "./style.css";
import { useEffect } from "react";
import BaseSubComponent from "./BaseSubComponent";
import { Dropdown, Modal } from "react-bootstrap";
import BASEURL from "../../Config/global";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import CustomInput from "../../Components/CustomInput";
import { AddImageComponent } from "../CategoryManagement/ImageComponent";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import CustomTable from "../../Components/CustomTable";
import BackButton from "../../Components/BackButton";
import { postRequest, putRequest } from "../../Util/requests";


const AddSoundSubCategory = () => {
    const Navigate = useNavigate()
    const [editId, setEditId] = useState();
    const [subCategories, setSubCategories] = useState([])
    const [showSwapModal, setShowSwapModal] = useState(false);
    const [swapInputError, setInputError] = useState({ error: true, text: '' });
    const [assetTitle, setAssetTitle] = useState("");
    const [assetDescription, setAssetDescription] = useState("");
    const [platforms, setPlatformList] = useState([]);
    const [payloadData, setPayloadData] = useState([]);

    

    const { id } = useParams();

    const [formData, setFormData] = useState({})

    useEffect(() => {
        getExistingAsset(id)
        fetchPlatformData()
    }, [id]);

    async function fetchPlatformData() {
        try {

            const response = await axios.get(
                `${BASEURL}/api/user/platforms/`
            );
            if (response.data.error !== true) {
                // console.log(response.data.data);
                setPlatformList(response.data.data);
                // setPlatformList(response.data.count);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getExistingAsset = async (id) => {
        if (!id) {
            return
        }
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch(`${BASEURL}/api/user/upload_asset/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    const { upload_asset_coupon_detail, title, description, } = result?.data
                    setAssetTitle(title)
                    setAssetDescription(description)
                    if (upload_asset_coupon_detail) {
                        setSubCategories(upload_asset_coupon_detail)
                    }
                }
            })
            .catch((error) => console.error(error));
    }

    const postData = async (updatedCategories, method) => {
            try {
                if (!updatedCategories && !method) {
                    return
                }
                const raw = JSON.stringify({
                    "title": assetTitle,
                    "description": assetDescription,
                    "upload_asset_coupon_detail": updatedCategories
                });
                const url = `/api/user/upload_asset/${id ? id + '/' : ''}`
                if (method === "POST") {
                    return await postRequest(url, raw);
                } else {
                    return await putRequest(url, raw);
                }

            } catch (error) {
                console.error(error);
            }
    };



    const addOrUpdateAssets = (event) => {
        if (assetTitle !== "" && assetDescription !== "") {
            questionModal
                .fire({
                    title: `Do you want to ${id ? "update" : "add"} this Asset?`,
                    confirmButtonText: id ? "Update" : "Add",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        postData(payloadData, id ? "PUT" : "POST").then((result) => {
                            successModal.fire({
                                text: `Asset ${id ? "Updated" : "Added"}  Successfully`,
                                confirmButtonText: "Continue",
                            });
                            Navigate("/asset-management")

                        })

                    }
                });

        }
    }

    const addCategory = (event) => {
        const { file_upload, size, description, instruction, title, platform_id } = formData;
        if (platform_id === undefined || platform_id === 'undefined' || file_upload === undefined || file_upload === null || size === "" || description === "" || instruction === "") {
            setInputError({ error: true, 'text': 'Please fill all fields to add this banner' })
            return
        }
        console.log("platform_id",platform_id)
        const foundPlatform = platforms.find(f => Number(f.id) === Number(platform_id))
        const platformName = foundPlatform.title
        if (editId !== "") {
            subCategories[editId] = { ...formData, platform_id, platform: { id: platform_id, title: platformName } }
            setSubCategories(subCategories)
            const findIndex = payloadData.findIndex(f=>f.id === formData.id);
            console.log("findIndex", findIndex)
            if (findIndex !== -1) {
                payloadData[findIndex] = { ...formData }
                // delete payloadData[findIndex].platform
                setPayloadData(payloadData)
            } else {
                setPayloadData(prevState=>[...prevState, { ...formData  }])

            }
            
        } else {
            setSubCategories(prevState=>[...prevState, { file_upload, size, description, instruction, title,platform_id,  platform: { id: platform_id, title: platformName } }])
            setPayloadData(prevState=>[...prevState, { file_upload, size, description, instruction, title, platform_id,  }])
        }
        setEditId("")
        setFormData({})
        setShowSwapModal(false)
    }

    const handleDeleteItemCallBack = (item, index) => {
        questionModal
            .fire({
                title: "Do you want to delete this sub asset",
                confirmButtonText: "Delete",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    if (!id) {
                        setSubCategories([...subCategories.filter((subcategory, i) => i !== index)]);
                    } else {
                        const requestOptions = {
                            method: "DELETE",
                            redirect: "follow"
                        };
                        fetch(`${BASEURL}/api/user/upload_asset/${id}/detail/${item.id}/`, requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                setSubCategories([...subCategories.filter((subcategory, i) => i !== index)]);
                                successModal.fire({
                                    text: "Sub category deleted Successfully",
                                    confirmButtonText: "Continue",
                                });
                            })
                            .catch((error) => console.error(error));
                    }


                }
            });
    }

    const couponsHeader = [
        {
            key: "platform",
            title: "platform",
        },
        {
            key: "image",
            title: "Image",
        },
        {
            key: "title",
            title: "Title",
        },
        {
            key: "size",
            title: "Size",
        },
        {
            key: "description",
            title: "description",
        },
        {
            key: "instructions",
            title: "instructions",
        },
        {
            key: "actions",
            title: "Actions",
        },
    ];
    const handleChange = (e) => {
        setAssetTitle(e.target.value)
    }
    const handleChangeDescription = (e) => {
        setAssetDescription(e.target.value)
    }
    const handleEditItemCallBack = (data, index) => {
        setFormData(data)
        setEditId(index)
        setShowSwapModal(true)
    }

console.log("payload", payloadData)
    return (
        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">
                        <BackButton />
                        Assets
                    </h2>
                </div>
              

            </div>
            <div className="container-fluid">
                <div className="row">   
                    <div className="col-3 mt-auto">
                        <CustomInput
                            label="Asset Page Title"
                            labelClass="mainLabel"
                            required
                            type="text"
                            description="description"
                            value={assetTitle || ""}
                            placeholder="Enter Title"
                            inputClass="mainInput"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-9 mt-auto">
                        <CustomInput
                            label="Asset Page Descriptions"
                            labelClass="mainLabel"
                            required
                            type="text"
                            description="description"
                            value={assetDescription || ""}
                            placeholder="Enter Descriptions"
                            inputClass="mainInput"
                            onChange={handleChangeDescription}
                        />
                    </div>

                </div>
                <div className="row">
                   
                </div>
                <br/>
                <br/>

                <br/>

                <div className="row mb-3">
                
                    
                    {
                        subCategories.length > 0 &&
                        <div className="col-12">
                            <CustomTable headers={couponsHeader}>
                                <tbody>
                                    {subCategories &&
                                        subCategories.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ width: '200px' }}>
                                                    {item.platform?.title}
                                                </td>
                                                <td style={{ width: '100px' }}>
                                                    {
                                                        item.file_upload && <img
                                                            src={item.file_upload}
                                                            alt="thumbnail"
                                                            className="thumbnail"
                                                        />
                                                    }

                                                </td>
                                                <td >{item.title}</td>
                                                <td >{item.size}</td>
                                                <td style={{maxWidth:'200px', overflow:'hidden', textOverflow:'ellipsis'}}>{item.description}</td>
                                                <td style={{maxWidth:'200px', overflow:'hidden', textOverflow:'ellipsis'}}>{item.instruction}</td>

                                                <td>
                                                    <Dropdown className="tableDropdown">
                                                        <Dropdown.Toggle
                                                            variant="transparent"
                                                            className="notButton classicToggle"
                                                        >
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu
                                                            align="end"
                                                            className="tableDropdownMenu"
                                                        >
                                                            <button
                                                                onClick={() => {
                                                                    handleEditItemCallBack(item, index)
                                                                }}
                                                                className={`tableAction`}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    className="tableActionIcon"
                                                                />
                                                                Edit
                                                            </button>

                                                            <button
                                                                className={`tableAction`}
                                                                onClick={() => {
                                                                    handleDeleteItemCallBack(item, index);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faTimes}
                                                                    className="tableActionIcon"
                                                                />
                                                                Delete
                                                            </button>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </CustomTable>
                        </div>
                    }
                    <div className="col-3 ms-auto text-end">
                        <button
                            onClick={() => {
                                setEditId("")
                                setFormData("")
                                setShowSwapModal(true)
                                setInputError({ error: false, text: '' })
                            }}
                            className={`customButton primaryButton`}
                        >
                            Add More
                        </button>

                    </div>
                    
                   

                </div>
                <div className="row">
                    <div className="col-lg-4 text-start mb-2">
                        <button
                            onClick={addOrUpdateAssets}
                            className={`customButton primaryButton `}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                show={showSwapModal}
                centered
                className="legal-form-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="pb-5 w-100 d-flex flex-column align-items-center">
                        <div className="d-flex justify-content-between w-100  ">
                            <h2 className="mainTitle m-4">{editId !== "" ? "Edit" : "Add"} Asset Details</h2>
                            <div className="ms-auto m-4">
                                <button
                                    className="closeButton notButton ms-auto"
                                    onClick={() => {
                                        setEditId("")
                                        setShowSwapModal(false)
                                        setInputError({ error: false, text: '' })

                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="cross-icon"
                                        color="white"
                                    />
                                </button>
                            </div>
                        </div>
                        <br />

                            <BaseSubComponent
                                formData={formData}
                                setFormData={setFormData}
                                inputClass="w-100 px-3 modal-image"
                                platforms={platforms}
                            />

                        {swapInputError?.error && <small className="text-danger mt-3">{swapInputError.text}</small>}
                        <div className=" pt-5 d-flex gap-3 justify-content-center">
                            <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={addCategory}>
                                <span className=''>{editId !== "" ? "UPDATE" : "Add"}</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </DashboardLayout>
    );
};
export default AddSoundSubCategory;