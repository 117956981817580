import { useState } from "react";
import "./style.css";
import { Eye, EyeSlash } from "../../Assets/svg";

const CustomInput = (props) => {
    const [typePass, setTypePass] = useState(true);

    const togglePassType = () => {
        setTypePass(!typePass);
    };

    return (
        <>
            <div className="inputWrapper">
                {props?.label && (
                    <label htmlFor={props?.id} className={props?.labelClass}>
                        {props?.label}
                        {props?.required ? "*" : ""}
                    </label>
                )}

                <div className="passwordWrapper">
                    <input
                        type={typePass ? "password" : "text"}
                        placeholder={props?.placeholder}
                        required={props?.required}
                        id={props?.id}
                        name={props?.name}
                        className={`${props?.inputClass} passInput`}
                        onChange={props?.onChange}
                        autoComplete={`new-${props?.name}`}
                        disabled={props?.disabled}

                    />
                    <button
                        type="button"
                        className="eyeButton"
                        onClick={togglePassType}
                    >
                        {typePass ? <EyeSlash /> : <Eye />}
                    </button>
                </div>
            </div>
        </>
    );
};
export default CustomInput;
