import { useState } from "react";
import { useParams, useNavigate } from "react-router";

import axios from "axios";

import Swal from "sweetalert2";
import { questionModal, successModal } from "../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./style.css";
import BASEURL, { FE_BASE_URL } from "../../Config/global";
import { useEffect } from "react";
import { placeholderImage } from "../../Assets/images";
import CustomInput from "../../Components/CustomInput";
import CustomLoader from "../../Components/CustomLoader";

import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from "draft-convert";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Modal } from "react-bootstrap";
import moment from "moment";
import { patchRequest } from "../../Util/requests";



const EditInfobox = () => {

  const navigate = useNavigate();
  const { id } = useParams();



  const [inputValue, setinputValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState(null);
  const [target, setTarget] = useState(false);
  const [unlimited, setUnlimited] = useState(false);
  const [minDate, setMinDate] = useState(new Date());

  const [formData, setFormData] = useState({});
  const [formImgShow, setFormImgShow] = useState({});
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [header_logo_info, set_header_logo_info] = useState('');
  const [mobile_logo, set_mobile_logo] = useState('');
  const [desktop_logo, set_desktop_logo] = useState('');



  // const [priEditorState, setPriEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // );

  const htmlData = '<p>This is your HTML data.</p>';

  // console.log(formData)
  // Convert HTML to ContentState
  // const blocksFromHTML = convertFromHTML(formData?.pri_html_info_text);
  // const contentState = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );

  // // Create EditorState from ContentState
  // const editorState = EditorState.createWithContent(contentState);

  // // Set the initial editor state
  // const [priEditorState, setPriEditorState] = useState(editorState);

  // // Optionally, you can get the raw content for saving to a database or for other purposes
  // const rawContent = convertToRaw(contentState);


  // const [priEditorState, setPriEditorState] = useState(() => {
  //   const blocksFromHTML = convertFromHTML(formData?.pri_html_info_text || '');
  //   const contentState = ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap
  //   );
  //   return EditorState.createWithContent(contentState);
  // });

  // useEffect(() => {
  //   if (formData?.pri_html_info_text) {
  //     const blocksFromHTML = convertFromHTML(formData?.pri_html_info_text);
  //     const contentState = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap
  //     );
  //     setPriEditorState(EditorState.createWithContent(contentState));
  //   }
  // }, [formData?.pri_html_info_text]);

  // Optionally, you can get the raw content for saving to a database or for other purposes
  // const rawContent = convertToRaw(priEditorState.getCurrentContent())




  // const [secEditorState, setSecEditorState] = useState(() => {
  //   const blocksFromHTML = convertFromHTML(formData?.sec_html_info_text || '');
  //   const contentState = ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap
  //   );
  //   return EditorState.createWithContent(contentState);
  // });

  // useEffect(() => {
  //   if (formData?.sec_html_info_text) {
  //     const blocksFromHTML = convertFromHTML(formData?.sec_html_info_text);
  //     const contentState = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap
  //     );
  //     setSecEditorState(EditorState.createWithContent(contentState));
  //   }
  // }, [formData?.sec_html_info_text]);

  // Optionally, you can get the raw content for saving to a database or for other purposes
  // const rawContentSec = convertToRaw(secEditorState.getCurrentContent())



  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/user/infoboxes/${id}`
        );
        setFormData(response.data.data);
        set_header_logo_info(response.data.data['header_logo_info']);
        set_mobile_logo(response.data.data['mobile_logo']);
        set_desktop_logo(response.data.data['desktop_logo']);

      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // 2018-12-31
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    setMinDate(year + '-' + month + '-' + day)
  }, [])

  // useEffect(() => {
  //   let html = draftToHtml(convertToRaw(priEditorState.getCurrentContent()));
  //   setFormData({ ...formData, 'pri_html_info_text': html });
  // }, [priEditorState]);

  // useEffect(() => {
  //   let html = draftToHtml(convertToRaw(secEditorState.getCurrentContent()));
  //   setFormData({ ...formData, 'sec_html_info_text': html });
  // }, [secEditorState]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const postData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("slider_name", formData.slider_name);
    // HTML info tray
    formDataToSend.append("company_title", formData.company_title);
    formDataToSend.append("html_info_text", formData.html_info_text);
    (formImgShow.header_logo_info != undefined && formImgShow.header_logo_info != null) && formDataToSend.append("header_logo_info", formImgShow.header_logo_info);
    (formImgShow.mobile_logo != undefined && formImgShow.mobile_logo != null) && formDataToSend.append("mobile_logo", formImgShow.mobile_logo);
    (formImgShow.desktop_logo != undefined && formImgShow.desktop_logo != null) && formDataToSend.append("desktop_logo", formImgShow.desktop_logo);
    console.log(formImgShow)

    setLoader(true);
    try {
      const response = await patchRequest(`/api/user/infoboxes/${id}/`, formDataToSend, null)
      console.log(response)
      if (!response.data.error) {
        successModal.fire({
          text: "Info Box Updated Successfully",
          confirmButtonText: "Continue",
        });
        setLoginError({ error: false, text: "" });
        setLoader(false);
        navigate("/infobox-management");
      } else {
        setLoginError({ error: true, text: response.data.message });
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.slider_name) {
      questionModal
        .fire({
          title: "Do you want to update this Info Box?",
          confirmButtonText: "Update",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postData();
          }
        });
    }
    else {
      setLoginError({ error: true, text: "Please Fill All the Requires Fields" });
    }
  };

  const priHeaderInfoImg = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_header_logo_info(reader.result);
      };
    }
  }

  const priMobileLogo = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_mobile_logo(reader.result);
      };
    }
  }

  const priDesktopLogo = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_desktop_logo(reader.result);
      };
    }
  }





  console.log("formData", formData)
  const handleCopy = () => {
    const text = `${FE_BASE_URL}/${isCopied}/${formData.code}`
    navigator.clipboard.writeText(text);
    setShowModal(false)
  }
  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Edit Info Box
              </h2>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">



            <div className="col-lg-4 mb-2">
                <CustomInput
                  label="Slider Name"
                  labelClass="mainLabel"
                  type="text"
                  name="slider_name"
                  value={formData?.slider_name || ""}
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 mb-2">
                <CustomInput
                  label="Created Date"
                  labelClass="mainLabel"
                  type="text"
                  name="created"
                  value={moment(formData.created).format('MM/DD/YYYY')}
                  inputClass="mainInput"
                  // onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 mb-2">
                <CustomInput
                  label="Modified Date"
                  labelClass="mainLabel"
                  type="text"
                  name="updated"
                  value={moment(formData.updated).format('MM/DD/YYYY')}
                  inputClass="mainInput"
                  // onChange={handleChange}
                />
              </div>
            
              {/* Primary User */}
              <div className="col-lg-3 mb-2">
                <CustomInput
                  label="Company Title"
                  labelClass="mainLabel mb-3"
                  type="text"
                  name="company_title"
                  value={formData?.company_title || ""}
                  placeholder="Enter Company Title"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Header Information Image</p>
                  <div className="imageInput couponImageInput">
                    {header_logo_info ? (
                      <img src={header_logo_info} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="header_logo_info"
                    onChange={priHeaderInfoImg}
                  />
                </label>
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Mobile Image</p>
                  <div className="imageInput couponImageInput">
                    {mobile_logo ? (
                      <img src={mobile_logo} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="mobile_logo"
                    onChange={priMobileLogo}
                  />
                </label>
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Desktop Image</p>
                  <div className="imageInput couponImageInput">
                    {desktop_logo ? (
                      <img src={desktop_logo} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="desktop_logo"
                    onChange={priDesktopLogo}
                  />
                </label>
              </div>
              {/* <div className="col-lg-12">
                <div className="App" >
                  <label class="mainLabel">HTML Information</label>
                  <Editor
                    // editorState={priEditorState}
                    // onEditorStateChange={newEditorState => setPriEditorState(newEditorState)}
                    editorState={priEditorState}
                    onEditorStateChange={newEditorState => setPriEditorState(newEditorState)}            
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ['inline', 'colorPicker', 'link', 'embedded', 'image', 'blockType', 'fontSize', 'list', 'textAlign', 'remove'],
                      link: {
                        showOpenOptionOnHover: true,
                      },
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                    }}
                  />


                </div>
              </div> */}
              <div className="col-lg-12 mb-2">
                <div className="inputWrapper">
                  <label className="mainLabel">HTML Information</label>
                  <textarea
                    rows="10"
                    placeholder=""
                    required=""
                    name="html_info_text"
                    className="mainInput"
                    value={formData.html_info_text || ""}
                    onChange={handleChange}
                  ></textarea></div>
              </div>



            </div>
            {loginError.error == true && (
              <div className="row mb-3">
                <div className="col-12">
                  <p className="secondaryText">{loginError.text}</p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <CustomButton
                  type="submit"
                  variant="primaryButton"
                  text="Update Info Box"
                />
              </div>
            </div>
          </form>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
      <Modal
        show={showModal}
        centered
        className="swap-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="pb-5 w-100 d-flex flex-column align-items-center">
            <div className="text-end ms-auto">
              <button
                className="closeButton notButton ms-auto"
                onClick={() => { setShowModal(false); }}
              >

              </button>
            </div>
            <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '500px' }}>
              <div className="d-flex align-items-center mt-3 pt-3 flex-column">
                <h2 className="mb-0 me-4"><b className="mx-2">{"Copy Url"}</b></h2>
                <br />
                {
                  `${FE_BASE_URL}/${isCopied}/${formData.code}`
                }
              </div>
            </div>
            <div className="mt-3 pt-3 d-flex gap-3 justify-content-center">
              <FontAwesomeIcon
                className="mx-2"
                size={"2xl"}
                icon={faCopy}
                onClick={() => handleCopy()}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditInfobox
;
