import { useEffect, } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import SubcategorGeneral from "./subcategories/SubcategorGeneral";

const SubCategoryManagment = () => {

  useEffect(() => {
    document.title = "Relax Scofa | Category Management";
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">Sub Category</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Tabs
                defaultActiveKey="sounds"
                id="uncontrolled-tab-example"
                className="customTabs mb-4"
              >
                <Tab title="Sounds" eventKey={"sounds"}>
                  <SubcategorGeneral categoryUrl={`/api/sounds/soundscategories`} subCategoryUrl={`/api/sounds/soundssubcategories`} />
                </Tab>
                <Tab title="Relax & Rest" eventKey={"relaxations"}>
                  <SubcategorGeneral categoryUrl={`/api/meditation/meditationcategories`} subCategoryUrl={`/api/meditation/meditationsubcategories`} />
                  {/* <AddRelaxationSubCategory /> */}
                </Tab>
                <Tab title="Stories" eventKey={"story"}>
                  <SubcategorGeneral categoryUrl={`/api/stories/storiescategories`} subCategoryUrl={`/api/stories/storiessubcategories`} />
                  {/* <AddStorySubCategory /> */}
                </Tab>
                <Tab title="Articles" eventKey={"articles"}>
                  <SubcategorGeneral categoryUrl={`/api/articles/articlecategories`} subCategoryUrl={`/api/articles/articlesubcategories`} />
                  {/* <AddArticleSubCategory /> */}
                </Tab>
                <Tab title="Featured Mixes" eventKey={"featured"}>
                <SubcategorGeneral categoryUrl={`/api/sounds/mixFeatureCategories`} subCategoryUrl={`/api/sounds/mixsubcategories`} />
                  {/* <AddFeaturedMixSubCategory /> */}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default SubCategoryManagment;