import axios from 'axios'
import { getAccessToken, removeAccessToken } from './token'
import BASEURL from '../Config/global'

const handleUserTokenValidity = async () => {
    try {
        const token = getAccessToken()
        let headers = {
            'Authorization': 'Token ' + token,
            'Content-Type':'application/json'
        }
        const currentUser = localStorage.getItem("user_info");
        const parsedUser = JSON.parse(currentUser);
        const response = await axios.get(`${BASEURL}/api/user/check-user-token/${parsedUser.id}/`, { headers });
        if (response.data.status === 500) {
            removeAccessToken()
            window.location.href = "/login"
            // navigate("/login", { replace: true });
            return false;
        } else {
            return getAccessToken()
        }
    } catch (error) {
        console.error(error);
    }
}



export const patchRequest = async (url, payload, contentType = 'application/json') => {
    let token =await  handleUserTokenValidity()
    if (!token) {
        return {
            data: { error: true }
        }
    }
    let headers = {
        'Authorization': 'Token ' + token,
    }
    if (contentType) {
        headers['Content-Type'] = contentType
    }
    return await axios.patch(
        `${BASEURL}${url}`,
        payload,
        {
            headers: headers

        }
    )

}

export const putRequest = async (url, payload, contentType = 'application/json') => {
    let token = await handleUserTokenValidity()
    if (!token) {
        return {
            data: { error: true }
        }
    }
    let headers = {
        'Authorization': 'Token ' + token,
    }
    if (contentType) {
        headers['Content-Type'] = contentType
    }
    return await axios.put(
        `${BASEURL}${url}`,
        payload,
        {
            headers: headers

        }
    )

}

export const postRequest = async (url, payload, contentType = 'application/json') => {
    let token = await handleUserTokenValidity()
    if (!token) {
        return {
            data: { error: true }
        }
    }
    let headers = {
        'Authorization': 'Token ' + token,
    }
    if (contentType) {
        headers['Content-Type'] = contentType
    }
    return await axios.post(
        `${BASEURL}${url}`,
        payload,
        {
            headers: headers

        }
    )

}

export const deleteRequest = async (url) => {
    let token = await handleUserTokenValidity()
    if (!token) {
        return {
            data: { error: true }
        }
    }
    let headers = {
        'Authorization': 'Token ' + token,
    }
    return await axios.delete(
        `${BASEURL}${url}`,
        {
            headers: headers

        }
    )

}