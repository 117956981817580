import React, { useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout';
import { placeholderImage } from '../../Assets/images';
import './style.css';
import CustomButton from '../../Components/CustomButton';
import BASEURL from '../../Config/global';
import axios from 'axios';
import { postRequest } from '../../Util/requests';

const Media = () => {

    const [image, setImage] = useState(null);
    const [data, setData] = useState(null);
    const [message, setMessage] = useState(false);

    const handleImageChange = (event) => {
        setMessage(false);
        const file = event.target.files[0];
        setData(file)
        if (file === null || file === "undefined") {
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImage(reader.result);
            };
        }
    }

    const submit = async () => {
        setMessage(false);
        try {
            console.log(data)
            const formDataToSend = new FormData();
            formDataToSend.append("image", data);
            // const token = localStorage.getItem('admin_token');
            const response = await postRequest(`/api/user/image/`, formDataToSend, null)
            
            if (!response.data.error) {
                setMessage(true);
                setImage(null);
            } else {
                setMessage(false);
                // console.log("---")
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="container-fluid mt-5">
            <div className="row mb-3 pt-5">
                <div className="col-lg-12 mb-2">
                    <div className='coupon-payment-wrapper p-4 text-center'>
                        <h4 className="mb-4">Upload Image</h4>
                        <label className="w-100 mb-4">
                            <div className="imageInput couponImageInput mb-4">
                                {image ? (
                                    <img src={image} className="img" alt="Main" />
                                ) : (
                                    <img src={placeholderImage} alt="Main" />
                                )}
                            </div>
                            <input
                                type="file"
                                id="image"
                                accept="image/*"
                                className="d-none"
                                name="media"
                                onChange={handleImageChange}
                            />
                            <CustomButton onClick={() => submit()} className="customButton primaryButton mb-3" text={"Upload"} />
                            {message ? <p className='text-white'>Successfully Uploaded</p> : ''}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Media