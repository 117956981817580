import CategoryComponent from "../CategoryComponent";
const AddMeditationCategory = () => {
  return (
    <>
      <CategoryComponent
        name={"Relax & Rest"}
        baseAPI={`/api/meditation/meditationcategories`}
      />
    </>
  );
};
export default AddMeditationCategory;
